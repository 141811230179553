import { useGetPaymentMethods } from '@/query/queries/cash/useGetPaymentMethods';
import { Preloader } from '@/components/Preloader';
import { PaymentMethod } from './methods';
import s from './index.module.scss';

export const PaymentMethods = ({ amount = 100, selected, onChange }) => {
  const { data: list, isLoading } = useGetPaymentMethods();
  return (
    <div className={s.list}>
      {isLoading ? (
        <Preloader style={{ height: '150px' }} />
      ) : (
        list?.map(item => (
          <PaymentMethod
            key={item.type}
            amount={amount}
            data={item}
            onClick={() => onChange(item)}
            isSelected={selected === item.type}
          />
        ))
      )}
    </div>
  );
};
