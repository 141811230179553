import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { investingService } from '../../services/investingService';

export const useGetAllocatedRepaymentSchedule = fundraisingId => {
  return useQuery({
    enabled: false,
    queryKey: [QUERY_KEYS.investing.repayment_schedule, fundraisingId],
    queryFn: () =>
      investingService.repaymentSchedule(fundraisingId).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
