import { Tooltip } from 'react-tippy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Tippy } from '@/kit/Tippy';
import InfoIcon from '@/kit/Tippy/tooltip_icon.svg';
import { LOAN_GROUPS, LOAN_GROUPS_IDS } from '@/helpers/constants';
import { useAllocationStatus } from '../../../utils/useAllocationStatus';
import { Table } from './table';
import s from './index.module.scss';

export const EarningSchedule = ({ data }) => {
  const { t } = useTranslation('investing');
  const { t: tConstants } = useTranslation('constants');
  const [tab, setTab] = useState('conservative');

  const { allocatedGroupA, allocatedGroupB } = useAllocationStatus();

  useEffect(() => {
    if (allocatedGroupA) {
      setTab(LOAN_GROUPS_IDS.conservative.id);
    } else if (allocatedGroupB) {
      setTab(LOAN_GROUPS_IDS.moderate.id);
    }
  }, [allocatedGroupA, allocatedGroupB]);

  if (!data || !tab) {
    return null;
  }

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);

  return (
    <>
      <div className={classnames(s.tabs_wrap, 'f-body f-500')}>
        <div className={s.tabs}>
          {!!allocatedGroupA && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS_IDS.conservative.id && 'active',
              )}
              onClick={() => setTab(LOAN_GROUPS_IDS.conservative.id)}
            >
              {LOAN_GROUPS_T.conservative.full_name}
            </div>
          )}
          {!!allocatedGroupB && (
            <div
              className={classnames(
                s.tab,
                tab === LOAN_GROUPS_IDS.moderate.id && 'active',
              )}
              onClick={() => setTab(LOAN_GROUPS_IDS.moderate.id)}
            >
              {LOAN_GROUPS_T.moderate.full_name}
            </div>
          )}
        </div>
        <Tippy
          render={(ref, open, setOpen) => (
            <Tooltip
              open={open}
              theme="light"
              trigger="click"
              arrow
              interactive
              html={
                <div ref={ref} className="f-body" style={{ maxWidth: '350px' }}>
                  {t('earnings_schedule.tooltip_planned_accrued_income')}
                </div>
              }
            >
              <img
                src={InfoIcon}
                alt="tooltip"
                width={24}
                height={24}
                style={{ display: 'flex', cursor: 'pointer' }}
                onClick={e => {
                  e.stopPropagation();
                  setOpen(true);
                }}
              />
            </Tooltip>
          )}
        />
      </div>
      <div style={{ width: '100%' }}>
        <Table
          tab={tab}
          data={data}
          allocatedAmount={
            tab === LOAN_GROUPS_IDS.conservative.id
              ? allocatedGroupA
              : tab === LOAN_GROUPS_IDS.moderate.id
                ? allocatedGroupB
                : 0
          }
        />
      </div>
    </>
  );
};
