import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prettyFloatMoney } from '@/helpers/utils';
import ImageSuccess from './success.svg';
import r from '@/kit/RoundButton/button.module.scss';
import s from '../index.module.scss';

export const PaymentResultSuccess = ({ result, onClose }) => {
  const { t } = useTranslation('investing');
  return (
    <div className={s.wrapper}>
      <div className={s.image}>
        <img src={ImageSuccess} alt={t('topup_success_modal.success')} />
      </div>
      <div className="f-body">{t('topup_success_modal.payment_completed')}</div>
      <div className="f-h1 f-500">
        {prettyFloatMoney(result.initial_amount)}
      </div>
      <button
        type="button"
        className={classnames(r.black_large, s.btn_done)}
        onClick={onClose}
      >
        {t('topup_success_modal.done')}
      </button>
    </div>
  );
};
