import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { cashService } from '../../services/cashService';

export const useGetBalance = () => {
  return useQuery({
    refetchInterval: 60000,
    refetchIntervalInBackground: false,
    queryKey: [QUERY_KEYS.cash.balance],
    queryFn: () =>
      cashService.balance().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
