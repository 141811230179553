import classnames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { prettyFloatMoney } from '@/helpers/utils';
import { useGetInvestingResults } from '@/query/queries/investing/useGetInvestingResults';
import { EMPTY_DASH } from '@/helpers/constants';
import { useHandleRoundStatus } from '../../provider';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

export const PreviousRound = () => {
  const { status } = useHandleRoundStatus();
  const { t } = useTranslation('investing');
  const { data: results, refetch: getResults } = useGetInvestingResults();

  useEffect(() => {
    status?.step === 5 && !results && getResults();
  }, [status, results]);

  return (
    <div className={s.wrapper}>
      <div className="f-h2 f-500">{t('prev_round.previous_round')}</div>
      {status.step === 4 ? (
        <div className={s.content}>
          {t('prev_round.round_processing_underway')}
        </div>
      ) : (
        <div className={s.content}>
          <div className={classnames(s.row, 'f-body')}>
            <div>{t('prev_round.total_invested')}</div>
            <div>
              {results ? prettyFloatMoney(results.total_invested) : EMPTY_DASH}
            </div>
          </div>
          <div className={classnames(s.row, 'f-body')}>
            <div>{t('prev_round.bonuses')}</div>
            <div>
              {results ? prettyFloatMoney(results.bonus_amount) : EMPTY_DASH}
            </div>
          </div>
          <MyLink
            className={classnames(r.black_small, s.btn_loans)}
            href={APP_LINKS.loans}
          >
            {t('prev_round.see_details')}
          </MyLink>
        </div>
      )}
    </div>
  );
};
