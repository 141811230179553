import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CURRENCY } from '@/helpers/constants';
import { InputField } from '@/kit/InputField';

export const MoneyInput = ({
  label,
  name,
  value,
  onChange,
  onClear,
  error,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    prefix: CURRENCY,
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: "'",
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // Limit to 2 decimal places
    integerLimit: null, // No limit on integer part
    allowNegative: false, // Do not allow negative numbers
    allowLeadingZeroes: false, // Do not allow leading zeroes
  });

  const handleBlur = e => {
    if (e.target.value === '') {
      onChange({ target: { name, value: 0 } });
    }
  };

  const handleChange = e => {
    const unmaskedValue = e.target.value.replace(/[^0-9.]/g, '');
    onChange({
      target: {
        name,
        value: unmaskedValue,
      },
    });
  };

  return (
    <MaskedInput
      mask={currencyMask}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      render={(ref, maskProps) => {
        return (
          <InputField
            ref={ref}
            name={name}
            label={label}
            onClear={onClear}
            error={error}
            placeholder={`${CURRENCY}0`}
            {...inputProps}
            {...maskProps}
          />
        );
      }}
    />
  );
};
