import { useTranslation } from 'react-i18next';
import { useResetInvesting } from '@/query/queries/investing/useResetInvesting';
import { scrollTop } from '@/helpers/utils';
import { useHandleRoundStatus } from '../../provider';
import s from './index.module.scss';

export const ResetInvesting = () => {
  const { status, addOnly } = useHandleRoundStatus();
  const { mutateAsync: reset } = useResetInvesting();
  const { t } = useTranslation('investing');

  const onReset = async () => {
    await reset();
    scrollTop();
  };

  if (status?.step < 2 || status?.step > 3 || addOnly) return null;

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div className="f-body f-500">{t('reset_inv.changed_your_mind')}</div>
        <div className="f-body">{t('reset_inv.decide_not_to_participate')}</div>
      </div>
      <button type="button" className={s.btn_reset} onClick={onReset}>
        {t('reset_inv.reset_investing')}
      </button>
    </div>
  );
};
