import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getMessage } from '@/helpers/utils';
import { QUERY_KEYS } from '@/query/queryKeys';
import Notify from '@/components/Notification';
import { investingService } from '../../services/investingService';

export const useConfirmInvestment = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: payload =>
      investingService.confirmInvestment(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          queryClient.invalidateQueries([QUERY_KEYS.investing.status]);
          queryClient.invalidateQueries([QUERY_KEYS.cash.balance]);
          queryClient.invalidateQueries([
            QUERY_KEYS.investing.pre_allocate_info,
          ]);
          // TODO check of usage below request in DEV-497
          // invalidateQueries getIncreasedIncomeStatus()
          return { ...data, success: true };
        }

        if (status === 422) {
          Notify.error({ text: getMessage(data) });
        }

        throw new Error(data?.message);
      }),
  });
};
