import { createPortal } from 'react-dom';
import IconClose from '../../assets/icon_close.svg';
import s from './index.module.scss';

export const ModalCenterLayout = ({ children, showClose, onClose }) => {
  return createPortal(
    <div className={s.wrapper}>
      <div className={s.backdrop} onClick={onClose} />
      <div className={s.content}>
        {children}

        {showClose && (
          <img
            src={IconClose}
            alt="close"
            width={24}
            height={24}
            onClick={onClose}
            className={s.icon}
          />
        )}
      </div>
    </div>,
    document.body,
  );
};
