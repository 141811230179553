import { METADATA } from '@/helpers/metadata';
import {
  GET_INVESTING_RESULTS,
  GET_INCREASED_INCOME_STATUS,
} from '../types/investTypes';

export const initialState = {
  isParticipating: false,
  investingResult: { ...METADATA.default, data: null },
  increasedIncomeStatus: { ...METADATA.default, data: null },
};

const investReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVESTING_RESULTS.REQUEST:
      return {
        ...state,
        investingResult: { ...state.investingResult, ...METADATA.request },
      };
    case GET_INVESTING_RESULTS.SUCCESS:
      return {
        ...state,
        investingResult: { ...METADATA.success, data: action.payload },
      };
    case GET_INVESTING_RESULTS.FAILURE:
      return {
        ...state,
        investingResult: {
          ...state.investingResult,
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };
    case GET_INCREASED_INCOME_STATUS.REQUEST:
      return {
        ...state,
        increasedIncomeStatus: {
          ...state.increasedIncomeStatus,
          ...METADATA.request,
        },
      };
    case GET_INCREASED_INCOME_STATUS.SUCCESS:
      return {
        ...state,
        increasedIncomeStatus: { ...METADATA.success, data: action.payload },
      };
    case GET_INCREASED_INCOME_STATUS.FAILURE:
      return {
        ...state,
        increasedIncomeStatus: {
          ...METADATA.error,
          error: action.error,
          data: null,
        },
      };

    default:
      return state;
  }
};

export default investReducer;
