import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import { getFundraisingDateRange } from '@/helpers/date';
import { DATE_FORMAT_DD_MMMM } from '@/helpers/common';
import { useGetFundraisingStats } from '@/query/queries/investing/useGetFundraisingStats';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { useGetParticipatingBrands } from '@/query/queries/investing/useGetParticipatingBrands';
import { useGetInvestedCountries } from '@/query/queries/investing/useGetInvestedCountries';
import { EMPTY_DASH } from '@/helpers/constants';
import { MODAL_IDS } from '../../utils/constants';
import IconCalendar from '../../assets/icons/icon_calendar.svg';
import IconShopping from '../../assets/icons/icon_shopping.svg';
import IconGlobe from '../../assets/icons/icon_globe.svg';
import IconTarget from '../../assets/icons/icon_target.svg';
import s from './index.module.scss';

export const RoundInfo = () => {
  const { openModal } = useModal();
  const { t } = useTranslation('investing');
  const { data: dates } = useGetFundraisingDates();
  const { data: stats } = useGetFundraisingStats();
  const { data: brands } = useGetParticipatingBrands();
  const { data: countries } = useGetInvestedCountries();

  return (
    <div className={s.wrapper}>
      <div className={classnames(s.title, 'f-h2 f-500')}>
        {dates?.is_active ? (
          <Trans
            ns="investing"
            i18nKey="round_info.the_round_is_active_until"
            values={{
              endDate: prettyDate(dates.end_date, DATE_FORMAT_DD_MMMM),
            }}
            components={{
              Span: <span />,
              SpanNoWrap: <span style={{ whiteSpace: 'nowrap' }} />,
            }}
          />
        ) : (
          t('round_info.next_round')
        )}
      </div>
      <div className={s.cards_outer}>
        <div className={s.cards}>
          <div className={s.card} style={{ cursor: 'unset' }}>
            <img src={IconCalendar} alt="date" width={24} height={24} />
            <div className={s.label}>{t('round_info.date')}</div>
            <div className="f-body f-500">
              {dates
                ? getFundraisingDateRange({
                    startDate: dates.start_date,
                    endDate: dates.end_date,
                  })
                : EMPTY_DASH}
            </div>
          </div>
          <div
            className={s.card}
            onClick={() => openModal(MODAL_IDS.round_modal)}
          >
            <img src={IconTarget} alt="goal" width={24} height={24} />
            <div className={s.label}>{t('round_info.fundraising_goal')}</div>
            <div className="f-body f-500">
              {stats
                ? t('round_info.fundraising_goal_numbers', {
                    current: prettyFloatMoney(stats.currently_allocated),
                    total: prettyFloatMoney(stats.total_goal),
                  })
                : EMPTY_DASH}
            </div>
          </div>
          <div
            className={s.card}
            onClick={() => openModal(MODAL_IDS.brands_modal)}
          >
            <img src={IconShopping} alt="brands" width={24} height={24} />
            <div className={s.label}>
              {t('round_info.brands_fundraising_money')}
            </div>
            <div className="f-body f-500">
              {brands?.pages[0]?.total_count || 0}
            </div>
          </div>
          <div
            className={s.card}
            style={{
              cursor: countries?.countries.length ? 'pointer' : 'unset',
            }}
            onClick={() =>
              countries?.countries.length && openModal(MODAL_IDS.country_modal)
            }
          >
            <img src={IconGlobe} alt="date" width={24} height={24} />
            <div className={s.label}>{t('round_info.investors')}</div>
            <div className="f-body f-500">
              {countries?.countries.length
                ? t('round_info.investors_from_countries', {
                    investors: countries?.total_participated_investors,
                    countries: countries?.countries.length,
                  })
                : 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
