import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { getMessage } from '@/helpers/utils';
import Notify from '@/components/Notification';
import i18n from '@/i18n';
import { investingService } from '../../services/investingService';

const { t } = i18n;

export const useResetInvesting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    retry: false,
    mutationFn: () =>
      investingService.resetInvesting().then(response => {
        const { status, data } = response;
        if (status === 200) {
          Notify.success({ text: t('kit:notify.investing_reset_success') });
          queryClient.setQueryData([QUERY_KEYS.investing.status], () => data);
          queryClient.invalidateQueries([
            QUERY_KEYS.cash.balance,
            QUERY_KEYS.investing.pre_allocate_info,
          ]);
          // dispatch(getAllocateInfo());
          // dispatch(getIncreasedIncomeStatus());
          return data;
        }
        Notify.error({
          text: getMessage(data, t('kit:notify.investing_reset_failed')),
        });

        console.error(data?.message);
      }),
  });
};
