import { useEffect } from 'react';
import { useFormik } from 'formik';
import classnames from 'classnames';
import Decimal from 'decimal.js-light';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { prettyFloatMoney } from '@/helpers/utils';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { MoneyInput } from '@/components/MoneyInput';
import { MyLink } from '@/components/MyLink';
import { Preloader } from '@/components/Preloader';
import { BonusForAllocation } from '@/components/Bonus/BonusForAllocation';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useSetAllocation } from '@/query/queries/investing/useSetAllocation';
import { useGetPreAllocateInfo } from '@/query/queries/investing/useGetPreAllocateInfo';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import { useHandleRoundStatus } from '../../provider';
import IconAdd from '../../assets/icons/icon_add_cash.svg';
import { useAllocationStatus } from '../../utils/useAllocationStatus';
import { useCalculateGroupsAllocation } from '../../utils/useCalculateGroupsAllocation';
import { MODAL_IDS } from '../../utils/constants';
import { getValidationSchema } from './schema';
import { FullAmount } from './full';
import { validate } from './utils';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.allocate_modal;

export const AllocateModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { refetch: getAllocationStatus, isFetching } = useGetPreAllocateInfo();
  const { openModal, openSingleModal, closeModal } = useModal();
  const { addOnly } = useHandleRoundStatus();
  const { isMobile } = useWindowSize();
  const { data: balance } = useGetBalance();
  const { t } = useTranslation('investing');

  useEffect(() => {
    getAllocationStatus();
  }, []);

  const {
    accounts,
    accountsByGroup,
    allocatedGroupA,
    allocatedGroupB,
    bonusAmount,
  } = useAllocationStatus();

  const onSuccessAllocation = () => {
    openSingleModal(MODAL_IDS.review_modal);
  };

  const { mutateAsync: setAllocation } = useSetAllocation(onSuccessAllocation);

  const onSubmit = async values => {
    const payload = {
      group_a_amount: values.group_a_amount,
      group_b_amount: values.group_b_amount,
    };
    await setAllocation(payload);
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    onSubmit,
    enableReinitialize: true,
    validationSchema: getValidationSchema(bonusAmount),
    validateOnChange: true,
    validate: values =>
      validate(values, accountsByGroup, allocatedGroupA, allocatedGroupB),
    initialValues: {
      group_a_amount: allocatedGroupA,
      group_b_amount: allocatedGroupB,
    },
  });

  const { remainingCash, remainingBonuses } = useCalculateGroupsAllocation(
    new Decimal(balance?.available)
      .add(balance?.on_hold)
      .sub(bonusAmount)
      .toNumber() || 0,
    bonusAmount,
    Number(values.group_a_amount) || 0,
    Number(values.group_b_amount) || 0,
  );

  const isLoading = isFetching || !accounts?.length;

  const form = (
    <form
      className={classnames(s.modal, 'scr_modal_grid')}
      onSubmit={handleSubmit}
    >
      <div className="f-h2 f-500">{t('allocate_modal.allocate_money')}</div>
      {isLoading ? (
        <Preloader style={{ height: '200px', paddingBottom: '50px' }} />
      ) : (
        <>
          <div className={s.content}>
            <div className={s.header}>
              <div className={s.header_left}>
                <div className={s.header_block}>
                  <div>{t('allocate_modal.my_cash')}</div>
                  <div className="f-body">
                    {prettyFloatMoney(remainingCash)}
                  </div>
                </div>
                <div className={s.header_block}>
                  <div>{t('allocate_modal.my_bonuses')}</div>
                  <div className="f-body">
                    {prettyFloatMoney(remainingBonuses)}{' '}
                    {t('allocate_modal.group_a_only')}
                  </div>
                </div>
              </div>
              <button
                type="button"
                className={classnames(s.btn_add, 'hov')}
                onClick={() => openModal(MODAL_IDS.add_cash_modal)}
              >
                <span>{t('allocate_modal.add_cash')}</span>{' '}
                <img
                  src={IconAdd}
                  alt={t('allocate_modal.add')}
                  width={24}
                  height={24}
                />
              </button>
            </div>

            {addOnly && (
              <div className={s.participation}>
                <div>{t('allocate_modal.already_allocated')}</div>
                {!!allocatedGroupB && (
                  <div className={s.row}>
                    <div>{t('allocate_modal.group_a')}</div>
                    <div>{prettyFloatMoney(allocatedGroupA)}</div>
                  </div>
                )}
                {!!allocatedGroupB && (
                  <div className={s.row}>
                    <div>{t('allocate_modal.group_b')}</div>
                    <div>{prettyFloatMoney(allocatedGroupB)}</div>
                  </div>
                )}
              </div>
            )}

            <div>
              {accountsByGroup.conservative.remaining_amount +
              allocatedGroupA ? (
                <MoneyInput
                  label={t('allocate_modal.to_group_a')}
                  name="group_a_amount"
                  value={values.group_a_amount}
                  onChange={handleChange}
                  error={
                    touched.group_a_amount &&
                    (errors.group_a_amount || errors.common)
                  }
                />
              ) : (
                <FullAmount
                  label={t('allocate_modal.to_group_a')}
                  text={t('allocate_modal.full_amount_group_a')}
                />
              )}
              <div className={classnames(s.input_desc, 'f-label')}>
                {t('allocate_modal.stable_income')}{' '}
                <button
                  type="button"
                  onClick={() => openModal(MODAL_IDS.compare_groups_modal)}
                >
                  {t('allocate_modal.learn_more')}
                </button>
              </div>
            </div>

            <div>
              {accountsByGroup.moderate.remaining_amount + allocatedGroupB ? (
                <MoneyInput
                  label={t('allocate_modal.to_group_b')}
                  name="group_b_amount"
                  value={values.group_b_amount}
                  onChange={handleChange}
                  error={
                    touched.group_b_amount &&
                    (errors.group_b_amount || errors.common)
                  }
                />
              ) : (
                <FullAmount
                  label={t('allocate_modal.to_group_b')}
                  text={t('allocate_modal.full_amount_group_b')}
                />
              )}
              <div className={classnames(s.input_desc, 'f-label')}>
                {t('allocate_modal.increased_risk')}{' '}
                <button
                  type="button"
                  onClick={() => openModal(MODAL_IDS.compare_groups_modal)}
                >
                  {t('allocate_modal.learn_more')}
                </button>
              </div>
            </div>

            <BonusForAllocation amount={values.group_a_amount} />

            <div
              className={classnames(s.footer_mobile, 'f-label')}
              style={{ color: 'var(--Grey-500, #908A94)' }}
            >
              {t('allocate_modal.all_loan_groups')}{' '}
              <MyLink href="#" underline>
                {t('allocate_modal.how_to_choose')}
              </MyLink>
            </div>
          </div>
          <div className={s.footer}>
            <div
              className={classnames(s.footer_desktop, 'f-label')}
              style={{ color: 'var(--Grey-500, #908A94)' }}
            >
              {t('allocate_modal.all_loan_groups')}{' '}
              <button
                type="button"
                className="underline"
                onClick={() => openModal(MODAL_IDS.compare_groups_modal)}
              >
                {t('allocate_modal.how_to_choose')}
              </button>
            </div>
            <button
              type="submit"
              className={classnames(r.black_large, s.btn_next)}
            >
              {t('allocate_modal.next')}
            </button>
          </div>
        </>
      )}
    </form>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalCenterLayout>
  );
};
