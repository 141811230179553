import { Tooltip } from 'react-tippy';
import { Tippy } from '@/kit/Tippy';
import Icon from './icon_tooltip.svg';

export const TermTooltip = ({ children, className }) => (
  <Tippy
    render={(ref, open, setOpen) => (
      <Tooltip
        theme="light"
        trigger="click"
        open={open}
        style={{ display: 'flex', width: '16px', height: '16px' }}
        html={
          <div ref={ref} className={className} style={{ maxWidth: '350px' }}>
            {children}
          </div>
        }
      >
        <img
          src={Icon}
          alt="icon"
          style={{
            display: 'inline-block',
            cursor: 'pointer',
          }}
          onClick={e => {
            e.stopPropagation();
            setOpen(true);
          }}
        />
      </Tooltip>
    )}
  />
);
