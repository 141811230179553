import { useMemo } from 'react';
import Decimal from 'decimal.js-light';

// Hook for local group allocation calculation
export const useCalculateGroupsAllocation = (
  cash,
  bonuses,
  amountA,
  amountB,
) => {
  return useMemo(() => {
    let remainingCash = new Decimal(cash).minus(amountB);
    let remainingBonuses = new Decimal(bonuses);
    let amountA_decimal = new Decimal(amountA);

    if (amountA_decimal.gt(remainingBonuses)) {
      remainingCash = remainingCash.minus(
        amountA_decimal.minus(remainingBonuses),
      );
      remainingBonuses = new Decimal(0);
    } else {
      remainingBonuses = remainingBonuses.minus(amountA_decimal);
    }

    return {
      calculatedCash: remainingCash.toNumber(),
      calculatedBonuses: remainingBonuses.toNumber(),
      remainingCash: Math.max(remainingCash.toNumber(), 0),
      remainingBonuses: Math.max(remainingBonuses.toNumber(), 0),
    };
  }, [cash, bonuses, amountA, amountB]);
};
