import { useEffect, useState } from 'react';

const DESKTOP_BREAKPOINT = 1380;
const TABLET_BREAKPOINT = 800;

export const useWindowSize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    setInnerWidth(window.innerWidth);
    document.querySelector(':root').style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    innerWidth,
    isTablet: innerWidth < DESKTOP_BREAKPOINT,
    isMobile: innerWidth < TABLET_BREAKPOINT,
  };
};
