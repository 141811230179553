import { InvestingStateProvider } from './provider';
import { InvestingModule } from './module';

const InvestingPage = () => (
  <InvestingStateProvider>
    <InvestingModule />
  </InvestingStateProvider>
);

export default InvestingPage;
