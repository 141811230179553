import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getVeriffStatus } from '@/store/actions/profileActions';
import { APP_LINKS } from '@/helpers/links';
import { getNameInitials, isParticipatingInvesting } from '@/helpers/utils';
import { BonusBanner } from '../../BonusProgram/banner';
import { MyLink } from '@/components/MyLink';
import { Avatar } from '@/components/UI/Avatar';
import VerificationProcessWizard from './VeriffProcessWizard';
import { MinInvestAmountWarning } from '@/components/Warnings/MinInvestAmountWarning';
import { NewInvestorTopUpFeeBanner } from '@/components/Banners/NewInvestorTopUpFeeBanner';
import { useGetInvestingStatus } from '@/query/queries/investing/useGetInvestingStatus';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import s from './index.module.scss';

export const NewUsersView = ({ isActiveFR }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('dashboard');
  const { data: balance } = useGetBalance();
  const { data: profile } = useSelector(state => state.profileStore.profile);
  const { data: status } = useGetInvestingStatus();

  useEffect(() => {
    getStatus();
    window.addEventListener('focus', getStatus);
    return () => {
      window.removeEventListener('focus', getStatus);
    };
  }, []);

  const getStatus = () => {
    dispatch(getVeriffStatus());
  };

  return (
    <>
      <NewInvestorTopUpFeeBanner className={s.banner} />
      <BonusBanner className={s.banner} />
      <div className={s.header}>
        <div className={s.header__row}>
          <MyLink href={APP_LINKS.profile} className={s.avatar__link}>
            <Avatar
              src={profile?.photo?.file}
              size={65}
              alt={getNameInitials(profile?.first_name, profile?.last_name)}
              nameInitials={getNameInitials(
                profile?.first_name,
                profile?.last_name,
              )}
              className={s.avatar}
              active={isParticipatingInvesting(status)}
            />
          </MyLink>
        </div>
        <div className={classnames('f-42 f-500', s.title)}>
          {profile?.first_name
            ? t('hey_name_complete_checklist', {
                name: profile.first_name,
              })
            : t('hi_there_complete_checklist')}
        </div>
      </div>

      {profile?.country_code === 'US' &&
        status.min_investment_amount === 1000 && (
          <MinInvestAmountWarning className={s.amount_warning} />
        )}

      {profile && balance && (
        <VerificationProcessWizard isActiveFR={isActiveFR} />
      )}
    </>
  );
};
