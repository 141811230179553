import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@/kit/Alert';
import { APP_LINKS } from '@/helpers/links';
import { MyLink } from '@/components/MyLink';
import { useGetMe } from '@/hooks/useGetMe';
import IconRed from './check.svg';
import IconWarning from './wait.svg';
import s from './index.module.scss';

// TODO implement user verification
export const VerifyYourIdentityAlert = () => {
  const { user } = useGetMe();
  const { t } = useTranslation('investing');
  const [show, setShow] = useState(true);
  const [checking, setChecking] = useState(true);

  if (!show || user?.is_verified) return null;

  return checking ? (
    <Alert
      type="danger"
      title={t('verify_identity.verify_your_identity')}
      description={t('verify_identity.verify_identity_description')}
      icon={
        <img
          src={IconRed}
          alt={t('verify_identity.identity')}
          width={24}
          height={24}
        />
      }
      action={
        <MyLink className={s.button} href={APP_LINKS.dashboard}>
          {t('verify_identity.complete_verification')}
        </MyLink>
      }
    />
  ) : (
    <Alert
      type="warning"
      title={t('verify_identity.wait_until_checked')}
      description={t('verify_identity.wait_description')}
      icon={
        <img
          src={IconWarning}
          alt={t('verify_identity.wait')}
          width={24}
          height={24}
        />
      }
    />
  );
};
