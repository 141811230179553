import { useState } from 'react';
import classnames from 'classnames';
import IconCopy from './icon_copy.svg?react';
import s from './index.module.scss';

export const CopyButton = ({ text, onSuccess, onFailure }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    setIsCopied(true);
    navigator?.clipboard?.writeText(text).then(onSuccess, onFailure);
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(s.copy_button, isCopied ? 'copied' : 'copy')}
      onMouseLeave={() => setIsCopied(false)}
    >
      <IconCopy />
      <span className="f-14 f-400">{isCopied ? 'copied' : 'copy'}</span>
    </button>
  );
};
