import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import IconBank from './icon_bank.svg';
import s from './index.module.scss';

export const BankMethod = ({ data, isSelected, onClick }) => {
  const { t } = useTranslation('investing');
  return (
    <div
      className={classnames(s.bank, isSelected && 'selected')}
      onClick={onClick}
    >
      <div className={s.bank_header}>
        <div className="f-body">{data.title}</div>
        <img
          className={s.bank_img}
          src={IconBank}
          alt={t('bank_method.bank')}
          width={24}
          height={24}
        />
      </div>
      <div className="f-label">
        {t('bank_method.no_transfer_fees_processing_time')}
      </div>
    </div>
  );
};
