import Decimal from 'decimal.js-light';

export const calculateCardCommission = (
  amount,
  fixedCommission,
  commission,
) => {
  const am1 = new Decimal(amount).add(fixedCommission);
  const am2 = new Decimal(commission).div(100);
  const am3 = new Decimal(1).sub(am2);
  return am1.div(am3).sub(amount).toFixed(2, 8);
};
