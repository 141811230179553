import { useEffect, useState } from 'react';
import moment from 'moment/min/moment-with-locales';
import { XIRR } from '@/helpers/xirr';
import { round } from '@/helpers/utils';
import { DATE_REQUEST } from '@/helpers/common';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { useGetBonusGroup } from '@/query/queries/marketing/useGetBonusGroup';
import { useGetInvestingStatus } from '@/query/queries/investing/useGetInvestingStatus';
import { useGetAllocatedRepaymentSchedule } from '@/query/queries/investing/useGetAllocatedRepaymentSchedule';
import { useAllocationStatus } from '../utils/useAllocationStatus';

export const useCalculateEarnings = () => {
  const [state, setState] = useState({
    bonusAmount: null,
    increasedIncome: null,
    apr: null,
    totalAllocated: null,
    plannedEarning: null,
    earnings: null,
  });

  const { data: fundraisingDates } = useGetFundraisingDates();
  const { data: bonusGroup } = useGetBonusGroup();
  const { data: status } = useGetInvestingStatus();

  const { accounts, allocatedGroupA, totalAllocated, hasConfirmed } =
    useAllocationStatus();
  const activeFundraisingId =
    fundraisingDates?.is_active && fundraisingDates?.id;
  const { data: schedule } = useGetAllocatedRepaymentSchedule(
    activeFundraisingId,
    { enabled: hasConfirmed },
  );

  useEffect(() => {
    if (bonusGroup && status && accounts) {
      const prevBonus = status?.bonus_amount || 0;
      const bonusAmount =
        Math.max(
          parseInt(
            (allocatedGroupA - prevBonus) / bonusGroup.investment_amount,
          ),
          0,
        ) * bonusGroup.bonus_amount;
      setState(prev => ({ ...prev, bonusAmount }));
    }
  }, [bonusGroup, status, allocatedGroupA, accounts]);

  const calculateAPR = () => {
    if (fundraisingDates && totalAllocated && schedule) {
      const dates = [
        moment(fundraisingDates.end_date).add('1', 'day').format(DATE_REQUEST),
      ];
      const values = [0 - totalAllocated];

      schedule.repayment_schedule.map(item => {
        dates.push(item.date);
        values.push(item.total);
      });

      if (schedule.increased_income) {
        dates.push(
          schedule.repayment_schedule[schedule.repayment_schedule.length - 1]
            .date,
        );
        values.push(schedule.increased_income);
      }

      if (state.bonusAmount) {
        dates.push(
          schedule.repayment_schedule[schedule.repayment_schedule.length - 1]
            .date,
        );
        values.push(state.bonusAmount);
      }
      return round(XIRR(values, dates) * 100, { roundUpper: true });
    }
    return null;
  };

  useEffect(() => {
    if ((fundraisingDates, totalAllocated, schedule)) {
      const apr = calculateAPR(fundraisingDates, totalAllocated, schedule);
      setState(prev => ({ ...prev, apr }));
    }
  }, [fundraisingDates, totalAllocated, schedule, state.bonusAmount]);

  useEffect(() => {
    if (schedule) {
      setState(prev => ({
        ...prev,
        earnings: schedule.total_earnings,
        plannedEarning: schedule.total_earnings + schedule.increased_income,
      }));
    }
  }, [schedule]);

  return state;
};
