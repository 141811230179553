import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { investingService } from '../../services/investingService';

export const useSetInvestmentStrategy = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: payload =>
      investingService.setStrategy(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          queryClient.setQueryData([QUERY_KEYS.investing.status], prevData => ({
            ...prevData,
            ...data,
          }));
          return data;
        }
        throw new Error(data?.message);
      }),
  });
};
