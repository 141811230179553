import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { investingService } from '../../services/investingService';

export const useGetPreAllocateInfo = (options = { enabled: false }) => {
  return useQuery({
    ...options,
    queryKey: [QUERY_KEYS.investing.pre_allocate_info],
    queryFn: () =>
      investingService.preAllocateInfo().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
