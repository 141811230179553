import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { LOAN_GROUPS } from '@/helpers/constants';
import { TERM_TOOLTIPS } from './tooltips';
import s from './mobile.module.scss';

export const MobileTermView = () => {
  const { t } = useTranslation('investing');
  const { t: tLoanTerms } = useTranslation('loan_terms');
  const { t: tConstants } = useTranslation('constants');

  const TERM_TOOLTIPS_T = TERM_TOOLTIPS(tLoanTerms);

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const GROUPS = [LOAN_GROUPS_T.conservative, LOAN_GROUPS_T.moderate];

  return (
    <div className={s.wrapper}>
      <div className={classnames(s.row, 'f-body')}>
        {GROUPS.map((group, index) => (
          <h3 className={classnames(s.name, 'f-body f-500')} key={index}>
            {group.full_name}
          </h3>
        ))}
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.investor_earnings')}
          </div>
          {TERM_TOOLTIPS_T.investor_earnings()}
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{GROUPS[0].investor_earnings}</span>
          <span>{GROUPS[1].investor_earnings}</span>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.net_annual_return')}
          </div>
          {TERM_TOOLTIPS_T.target_annual_return({ className: s.m_return })}
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{GROUPS[0].target_annual_return}</span>
          <span>{GROUPS[1].target_annual_return}</span>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.term')}
          </div>
          {TERM_TOOLTIPS_T.term({ className: s.m_term })}
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{t('compare_modal.6_months')}</span>
          <span>{t('compare_modal.6_months')}</span>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.repayments')}
          </div>
          {TERM_TOOLTIPS_T.repayment_type({ className: s.m_repayments })}
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{GROUPS[0].repayment_type}</span>
          <span>{GROUPS[1].repayment_type}</span>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.skin_in_the_game')}
          </div>
          {TERM_TOOLTIPS_T.skin_in_the_game({ className: s.m_skin })}
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{GROUPS[0].skin_in_the_game}</span>
          <span>{GROUPS[1].skin_in_the_game}</span>
        </div>
      </div>

      <div className={s.block}>
        <div className={s.header}>
          <div className={classnames(s.label, 'f-body')}>
            {t('compare_modal.protection')}
          </div>
        </div>
        <div className={classnames(s.row, 'f-body')}>
          <span>{GROUPS[0].protection}</span>
          <span>{GROUPS[1].protection}</span>
        </div>
      </div>
    </div>
  );
};
