import React from 'react';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { Preloader } from '@/components/Preloader';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useGetParticipatingBrands } from '@/query/queries/investing/useGetParticipatingBrands';
import { MODAL_IDS } from '../../utils/constants';
import { BrandCard } from './card';
import s from './index.module.scss';

const id = MODAL_IDS.brands_modal;

export const BrandsModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { closeModal } = useModal();
  const { isMobile } = useWindowSize();
  const { data, fetchNextPage, hasNextPage } = useGetParticipatingBrands();

  const isLoading = !data;

  const content = (
    <div className={classnames(s.modal, 'scr_modal_grid')}>
      <div className="f-h2 f-500">Goods brands batch</div>
      {isLoading ? (
        <Preloader style={{ height: '200px', paddingBottom: '50px' }} />
      ) : (
        <>
          <div className={s.content} id="scrollableDiv">
            <InfiniteScroll
              dataLength={data.pages.length || 0}
              next={fetchNextPage}
              hasMore={hasNextPage}
              className={s.list}
              loader={<h4>Loading...</h4>}
              scrollThreshold={0.8}
              scrollableTarget="scrollableDiv"
            >
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.list.map(brand => (
                    <BrandCard key={brand.id} brand={brand} />
                  ))}
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </div>
        </>
      )}
    </div>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalCenterLayout>
  );
};
