import { useMemo } from 'react';
import Decimal from 'decimal.js-light';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { prettyFloatMoney } from '@/helpers/utils';
import { calculateCardCommission } from '../../utils';
import { getCommissionInfo } from './utils';
import { useGetPaymentCalculatedAmount } from '@/query/queries/cash/useGetPaymentCalculatedAmount';
import s from './index.module.scss';

export const CardMethod = ({ amount, data, isSelected, onClick }) => {
  const { t } = useTranslation('investing');
  const { data: calculation } = useGetPaymentCalculatedAmount({
    amount,
    payment_method: data.type,
  });

  const commissions = {
    eu: calculateCardCommission(amount, 0.25, 1.5),
    uk: calculateCardCommission(amount, 0.25, 2.5),
    international: calculateCardCommission(amount, 0.25, 3.25),
  };

  const commission = useMemo(() => {
    if (!calculation) return 0;
    return new Decimal(calculation.amount).sub(amount).toNumber();
  }, [amount, calculation]);

  return (
    <div
      className={classnames(s.card, isSelected && 'selected')}
      onClick={onClick}
    >
      <div className={s.card_header}>
        <div className="f-body">{data.title}</div>
        <img className={s.card_img} src={data.icon} alt={data.title} />
      </div>
      <div className="f-label">{getCommissionInfo(data.type, commission)}</div>
      <div className={classnames(s.method_commission_card, 'f-14 f-400')}>
        {t('card_method.transfer_fee_depends_on_card_issuer')}
        <ul className="scr-circle-list">
          <li>
            {t('card_method.european_cards_fee', {
              fee: prettyFloatMoney(commissions.eu),
            })}
          </li>
          <li>
            {t('card_method.uk_cards_fee', {
              fee: prettyFloatMoney(commissions.uk),
            })}
          </li>
          <li>
            {t('card_method.international_cards_fee', {
              fee: prettyFloatMoney(commissions.international),
            })}
          </li>
        </ul>
        {t('card_method.note_bank_may_charge_additional_fees')}
      </div>
    </div>
  );
};
