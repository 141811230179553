import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { BankRequisite } from '@/components/BankRequisite';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MODAL_IDS } from '../../utils/constants';
import s from './index.module.scss';

const id = MODAL_IDS.bank_requisites;

export const BankRequisitesModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { closeModal } = useModal();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation('investing');

  const content = (
    <div className={classnames(s.modal, 'scr_modal_grid')}>
      <div className="f-h2 f-500">
        {t('bank_requisites_modal.bank_account_details')}
      </div>
      <div className={s.content}>
        <BankRequisite />
        <div className={classnames(s.note, 'f-label')}>
          {t('bank_requisites_modal.note')}
        </div>
      </div>
    </div>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalCenterLayout>
  );
};
