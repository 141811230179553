import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { padNumber } from '@/helpers/utils';
import { useGetMe } from '@/hooks/useGetMe';
import { CONTACT_EMAIL } from '@/helpers/constants';
import { CopyButton } from '@/components/CopyButton';
import IconWarning from './icon_warning.svg?react';
import s from './index.module.scss';

const BANK_REQUISITES = {
  iban: 'EE947700771006351972',
  visibleIban: 'EE94 7700 7710 0635 1972',
  bankSwiftCode: 'LHVBEE22',
  beneficiaryName: 'Scramble OU',
  beneficiaryBankName: 'AS LHV Pank',
  bankAddress: 'Tartu mnt 2, 10145 Tallinn',
};

export const BankRequisite = ({ className }) => {
  const { t } = useTranslation('funds');
  const { user } = useGetMe();

  return (
    <div className={classnames(s.wrapper, className)}>
      <ul className={s.list}>
        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>{t('bank_requisites.beneficiary_name')}</div>
          <div className={s.value}>{BANK_REQUISITES.beneficiaryName}</div>
          <CopyButton text={BANK_REQUISITES.beneficiaryName} />
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>
            {t('bank_requisites.beneficiary_bank_account_number_iban')}
          </div>
          <div className={s.value}>{BANK_REQUISITES.visibleIban}</div>
          <CopyButton text={BANK_REQUISITES.iban} />
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>{t('bank_requisites.payment_details')}</div>
          <div className={s.value}>{padNumber(user?.id)}</div>
          <div className={classnames(s.note, 'f-12 f-400')}>
            <IconWarning />{' '}
            {t('bank_requisites.include_this_so_we_can_find_your_payment')}
          </div>
          <CopyButton text={padNumber(user?.id)} />
          <span
            className={classnames(s.required, 'hidable f-14 f-400')}
            id="hide"
          >
            {t('bank_requisites.required')}
          </span>
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>
            {t('bank_requisites.beneficiary_bank_swift_bic_code')}
          </div>
          <div className={s.value}>{BANK_REQUISITES.bankSwiftCode}</div>
          <CopyButton text={BANK_REQUISITES.bankSwiftCode} />
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>
            {t('bank_requisites.beneficiary_bank_name')}
          </div>
          <div className={s.value}>{BANK_REQUISITES.beneficiaryBankName}</div>
          <CopyButton text={BANK_REQUISITES.beneficiaryBankName} />
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>
            {t('bank_requisites.beneficiary_bank_address')}
          </div>
          <div className={s.value}>{BANK_REQUISITES.bankAddress}</div>
          <CopyButton text={BANK_REQUISITES.bankAddress} />
        </li>

        <li className={classnames(s.item, 'f-body')}>
          <div className={s.label}>{t('bank_requisites.company_email')}</div>
          <div className={s.value}>{CONTACT_EMAIL}</div>
          <CopyButton text={CONTACT_EMAIL} />
        </li>
      </ul>
    </div>
  );
};
