import { useFormik } from 'formik';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { prettyFloatMoney } from '@/helpers/utils';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { CheckboxButton } from '@/kit/form/Checkbox';
import { MyLink } from '@/components/MyLink';
import { Preloader } from '@/components/Preloader';
import { useWindowSize } from '@/hooks/useWindowSize';
import { EMPTY_DASH, INVESTMENT_STRATEGIES } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import { useGetInvestingStatus } from '@/query/queries/investing/useGetInvestingStatus';
import { useConfirmInvestment } from '@/query/queries/investing/useConfirmInvestment';
import { useGetReferralCode } from '@/query/queries/marketing/useGetReferralCode';
import { useAllocationStatus } from '../../utils/useAllocationStatus';
import { useCalculateBonuses } from '../../utils/useCalculateBonuses';
import { MODAL_IDS } from '../../utils/constants';
import { VALIDATION_SCHEMA } from './schema';
import IconBack from './icon_back.svg';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.review_modal;

export const ReviewModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { t } = useTranslation('investing');
  const { isMobile } = useWindowSize();
  const { openSingleModal, closeModal, closeAllModals } = useModal();
  const { data: status } = useGetInvestingStatus();
  const { data: referralCode } = useGetReferralCode();
  const { mutateAsync: confirmInvestment } = useConfirmInvestment();
  const { accounts, allocatedGroupA, allocatedGroupB, totalAllocated } =
    useAllocationStatus();
  const { bonus } = useCalculateBonuses();
  const isActiveReferralBanner = referralCode?.is_referral_banner_active;

  const onSubmit = async () => {
    const payload = {
      has_accepted_terms_a: !!allocatedGroupA,
      has_accepted_terms_b: !!allocatedGroupB,
    };

    const res = await confirmInvestment(payload);
    if (res?.success) {
      closeAllModals();
      isActiveReferralBanner &&
        setTimeout(() => {
          // TODO refactor bonus_invite_friend_investing modal
          // dispatch(
          //   setModals({
          //     type: MODAL_TYPES.bonus_invite_friend_investing,
          //   }),
          // );
        }, 20000);
    }
  };

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    onSubmit,
    validationSchema: VALIDATION_SCHEMA(t),
    initialValues: {
      has_accepted_terms: false,
    },
  });

  const isLoading = !accounts?.length;

  if (!status) return null;

  const form = (
    <form
      className={classnames(s.modal, 'scr_modal_grid')}
      onSubmit={handleSubmit}
    >
      <div className="f-h2 f-500">
        {t('review_modal.review_your_participation')}
      </div>
      {isLoading ? (
        <Preloader style={{ height: '200px', paddingBottom: '50px' }} />
      ) : (
        <>
          <div className={s.content}>
            <div className="f-body" style={{ marginBottom: '8px' }}>
              {t('review_modal.cancel_allocation_notice')}
            </div>

            <div className={s.block}>
              <div className={classnames(s.row, 'f-body')}>
                <div>{t('review_modal.strategy')}</div>
                <>
                  {status.option === INVESTMENT_STRATEGIES.not_selected && (
                    <span>{EMPTY_DASH}</span>
                  )}
                  {status.option === INVESTMENT_STRATEGIES.standard && (
                    <span>{t('review_modal.standard_strategy')}</span>
                  )}
                  {status.option === INVESTMENT_STRATEGIES.priority && (
                    <span>{t('review_modal.priority_strategy')}</span>
                  )}
                </>
              </div>
            </div>
            <div className={s.title}>{t('review_modal.allocation')}</div>
            <div className={s.block}>
              <div className={classnames(s.row, 'f-body')}>
                <div>{t('review_modal.group_a')}</div>
                <div>{prettyFloatMoney(allocatedGroupA)}</div>
              </div>
              <div className={classnames(s.row, 'f-body')}>
                <div>{t('review_modal.group_b')}</div>
                <div>{prettyFloatMoney(allocatedGroupB)}</div>
              </div>
              <div className={classnames(s.row, 'f-body')}>
                <div>
                  {t('review_modal.bonuses')}{' '}
                  <span className={s.grey}>
                    {t('review_modal.bonuses_notice')}
                  </span>
                </div>
                <div>{prettyFloatMoney(bonus)}</div>
              </div>
              <div className={classnames(s.row, 'f-body')}>
                <div>{t('review_modal.total_allocated')}</div>
                <div>{prettyFloatMoney(totalAllocated)}</div>
              </div>
            </div>

            {!!(allocatedGroupA || allocatedGroupB) && (
              <div className={s.agreements}>
                <CheckboxButton
                  name="has_accepted_terms"
                  value="has_accepted_terms"
                  checked={values.has_accepted_terms}
                  error={
                    errors.has_accepted_terms &&
                    touched.has_accepted_terms &&
                    errors.has_accepted_terms
                  }
                  onChange={() =>
                    setFieldValue(
                      'has_accepted_terms',
                      !values.has_accepted_terms,
                    )
                  }
                >
                  {allocatedGroupB || (allocatedGroupA && allocatedGroupB) ? (
                    <span
                      className={classnames(
                        'f-label',
                        errors.has_accepted_terms &&
                          touched.has_accepted_terms &&
                          'error',
                        values.has_accepted_terms && 'checked',
                      )}
                    >
                      <Trans
                        ns="investing"
                        i18nKey="review_modal.acknowledge_and_agree_documents"
                        components={{
                          termsLink: (
                            <MyLink
                              href={APP_LINKS.termOfUse}
                              isExternal
                              underline
                            />
                          ),
                          assignmentLink: (
                            <MyLink
                              href={APP_LINKS.docAssignmentAgreement}
                              isExternal
                              underline
                            />
                          ),
                          guaranteeLetterLink: (
                            <MyLink
                              href={APP_LINKS.subordinatedGuaranteeLetter}
                              isExternal
                              underline
                            />
                          ),
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      className={classnames(
                        'f-label',
                        errors.has_accepted_terms &&
                          touched.has_accepted_terms &&
                          'error',
                        values.has_accepted_terms && 'checked',
                      )}
                    >
                      <Trans
                        ns="investing"
                        i18nKey="review_modal.acknowledge_and_agree_other_users"
                        components={{
                          termsLink: (
                            <MyLink
                              href={APP_LINKS.termOfUse}
                              isExternal
                              underline
                            />
                          ),
                          assignmentLink: (
                            <MyLink
                              href={APP_LINKS.docAssignmentAgreement}
                              isExternal
                              underline
                            />
                          ),
                        }}
                      />
                    </span>
                  )}
                </CheckboxButton>
              </div>
            )}
          </div>
          <div className={s.footer}>
            <button
              type="button"
              className={s.btn_back}
              onClick={() => openSingleModal(MODAL_IDS.allocate_modal)}
            >
              <img
                src={IconBack}
                alt={t('review_modal.back')}
                width={24}
                height={24}
                style={{ minWidth: '24px' }}
              />
              {t('review_modal.back')}
            </button>
            <button
              type="submit"
              onSubmit={handleSubmit}
              className={classnames(r.black_large, s.btn_submit)}
            >
              {t('review_modal.invest_in_the_round')}
            </button>
          </div>
        </>
      )}
    </form>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalCenterLayout>
  );
};
