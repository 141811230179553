export const GET_INVESTING_RESULTS = {
  REQUEST: 'GET_INVESTING_RESULTS_REQUEST',
  SUCCESS: 'GET_INVESTING_RESULTS_SUCCESS',
  FAILURE: 'GET_INVESTING_RESULTS_FAILURE',
};

export const GET_INCREASED_INCOME_STATUS = {
  REQUEST: 'GET_INCREASED_INCOME_STATUS_REQUEST',
  SUCCESS: 'GET_INCREASED_INCOME_STATUS_SUCCESS',
  FAILURE: 'GET_INCREASED_INCOME_STATUS_FAILURE',
};
