import { prettyFloatMoney } from '@/helpers/utils';
import { PAYMENT_PROCESSING_DAYS, PAYMENT_TYPES } from '../../constants';
import i18n from '@/i18n';

const { t } = i18n;

export const getCommissionInfo = (type, commission) => {
  const fee = commission
    ? t('investing:other_method.transfer_fee_is', {
        fee: prettyFloatMoney(commission),
      })
    : t('investing:other_method.no_transfer_fees');

  if ([PAYMENT_TYPES.sepa_debit, PAYMENT_TYPES.sofort].includes(type)) {
    return `${fee}. ${t('investing:other_method.processing_time_typically', { days: PAYMENT_PROCESSING_DAYS[type] })}`;
  }

  return `${fee}. ${t('investing:other_method.processing_immediately')}`;
};
