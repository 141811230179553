import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { cashService } from '../../services/cashService';

export const useGetPaymentResultStatus = paymentRef => {
  return useQuery({
    enabled: !!paymentRef,
    queryKey: [QUERY_KEYS.cash.top_up_result, [paymentRef]],
    queryFn: () =>
      cashService
        .paymentResult({ payment_reference: paymentRef })
        .then(response => {
          const { status, data } = response;
          if (status === 200) {
            return data;
          }
          return null;
        }),
  });
};
