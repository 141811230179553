import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { Preloader } from '@/components/Preloader';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useGetInvestedCountries } from '@/query/queries/investing/useGetInvestedCountries';
import { InvestedCountries } from '../../components/InvestedCountries';
import { MODAL_IDS } from '../../utils/constants';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.country_modal;

export const CountriesModal = () => {
  const { modalIds } = useModal();
  const { closeModal } = useModal();
  const { data } = useGetInvestedCountries();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation('investing');

  const content = (
    <div className={classnames(s.modal)}>
      <div className="f-h2 f-500">{t('countries_modal.investors')}</div>
      <div className={s.content}>
        {data ? (
          <InvestedCountries data={data} />
        ) : (
          <Preloader style={{ height: '148px' }} />
        )}
      </div>
      <div className={s.footer}>
        {data && (
          <div className={s.investors}>
            <div className={s.investors_label}>
              {t('countries_modal.total_investors')}
            </div>
            <div className={s.investors_total}>
              {data.total_participated_investors}
            </div>
          </div>
        )}
        <button
          type="button"
          className={classnames(r.black_large, s.btn_done)}
          onClick={() => closeModal(id)}
        >
          {t('countries_modal.done')}
        </button>
      </div>
    </div>
  );

  if (!modalIds[id] || !data) return null;

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalCenterLayout>
  );
};
