import { useHandleRoundStatus } from './provider';
import { RoundInfo } from './components/RoundInfo';
import { NextRoundInfo } from './components/RoundInfo/next';
import { InvestmentStrategies } from './components/Strategies';
import { VerifyYourIdentityAlert } from './components/alerts/VerifyYourIdentityAlert';
import { InvestingFaq } from './components/Faq';
import { Participation } from './components/Participation';
import { ResetInvesting } from './components/ResetInvesting';
import { RoundPrepare } from './components/RoundePrepare';
import { PreviousRound } from './components/PreviousRound';
import { Statistics } from './components/Statistics';
import { RoundModal } from './modals/RoundModal';
import { CountriesModal } from './modals/CountriesModal';
import { AllocateModal } from './modals/AllocateModal';
import { BrandsModal } from './modals/BrandsModal';
import { GroupsCompareModal } from './modals/GroupsCompareModal';
import { ReviewModal } from './modals/ReviewModal';
import { AddCashModal } from './modals/AddCashModal';
import { BankRequisitesModal } from './modals/BankRequisitesModal';
import { TopUpResultModal } from './modals/TopUpResultModal';
import { BrandDetailModal } from './modals/BrandDetailModal';
import s from './index.module.scss';

export const InvestingModule = () => {
  const { status, isParticipating } = useHandleRoundStatus();

  if (!status) return null;

  return (
    <div className="container-inner">
      <div className={s.content}>
        <Statistics />
        {[4, 5].includes(status.step) && <PreviousRound />}
        {[0, 1, 2, 3].includes(status.step) ? <RoundInfo /> : <NextRoundInfo />}
        <VerifyYourIdentityAlert />
        {isParticipating ? (
          <Participation />
        ) : (
          [0, 1, 2, 3].includes(status.step) && <InvestmentStrategies />
        )}
        {[-1, 4, 5].includes(status.step) && <RoundPrepare />}
        <ResetInvesting />
        <InvestingFaq step={status.step} />
      </div>
      <>
        <AllocateModal />
        <RoundModal />
        <CountriesModal />
        <BrandsModal />
        <GroupsCompareModal />
        <ReviewModal />
        <AddCashModal />
        <BankRequisitesModal />
        <TopUpResultModal />
        <BrandDetailModal />
      </>
    </div>
  );
};
