const BASE_KEYS = {
  referral: 'referral',
  auth: 'auth',
  user: 'user',
  investing: 'investing',
  common: 'common',
  cash: 'cash',
  marketing: 'marketing',
};

export const QUERY_KEYS = {
  referral: {
    validation: `${BASE_KEYS.referral}_validation`,
  },
  auth: {
    email_otp_status: `${BASE_KEYS.auth}_email_otp_status`,
  },
  user: {
    profile: `${BASE_KEYS.user}_profile`,
  },
  common: {
    faq: `${BASE_KEYS.common}_faq`,
  },
  investing: {
    status: `${BASE_KEYS.investing}_status`,
    repayment_schedule: `${BASE_KEYS.investing}_repayment_schedule`,
    pre_allocate_info: `${BASE_KEYS.investing}_pre_allocate_info`,
    fundraising_stats: `${BASE_KEYS.investing}_fundraising_stats`,
    invested_countries: `${BASE_KEYS.investing}_invested_countries`,
    participating_brands: `${BASE_KEYS.investing}_participating_brands`,
    fundraising_dates: `${BASE_KEYS.investing}_fundraising_dates`,
    allocate_info: `${BASE_KEYS.investing}_allocate_info`,
    result: `${BASE_KEYS.investing}_result`,
  },
  cash: {
    balance: `${BASE_KEYS.cash}_balance`,
    payment_methods: `${BASE_KEYS.cash}_payment_methods`,
    payment_calculated_amount: `${BASE_KEYS.cash}_payment_calculated_amount`,
    top_up_result: `${BASE_KEYS.cash}_top_up_result`,
  },
  marketing: {
    referral_code: `${BASE_KEYS.cash}_referral_code`,
    bonus_groups: `${BASE_KEYS.cash}_bonus_groups`,
  },
};
