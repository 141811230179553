import { useState } from 'react';
import { useFormik } from 'formik';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { getFullname, prettyFloatMoney } from '@/helpers/utils';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { MoneyInput } from '@/components/MoneyInput';
import { useWindowSize } from '@/hooks/useWindowSize';
import { PaymentMethods } from '@/containers/AddCashModule/PaymentMethods';
import { PAYMENT_TYPES } from '@/containers/AddCashModule/constants';
import { EMPTY_DASH } from '@/helpers/constants';
import { useGetMe } from '@/hooks/useGetMe';
import { useGetPaymentCalculatedAmount } from '@/query/queries/cash/useGetPaymentCalculatedAmount';
import { createLHVPaymentSession } from '@/store/services/lhvServices';
import { MODAL_IDS } from '../../utils/constants';
import { VALIDATION_SCHEMA } from './schema';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.add_cash_modal;

export const AddCashModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const [loading, setLoading] = useState(false);
  const { openSingleModal, closeModal } = useModal();
  const { user } = useGetMe();
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const { t } = useTranslation('investing');

  const onSubmit = async values => {
    if (values.method === PAYMENT_TYPES.bank) {
      return openSingleModal(MODAL_IDS.bank_requisites);
    }

    try {
      setLoading(true);
      const currentPathname = window.location.origin + location.pathname;
      const res = await createLHVPaymentSession({
        payment_method: values.method,
        amount: values.amount,
        redirect_url: currentPathname,
      });
      if (res?.success) {
        return window.location.replace(res.data.payment_link);
      }
    } finally {
      setLoading(false);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      onSubmit,
      validationSchema: VALIDATION_SCHEMA,
      initialValues: {
        amount: 100,
        method: PAYMENT_TYPES.card,
      },
    });

  const { data: calculatedAmount, isFetching } = useGetPaymentCalculatedAmount({
    amount: values.amount,
    payment_method: values.method,
  });

  const form = (
    <form
      className={classnames(s.modal, 'scr_modal_grid')}
      onSubmit={handleSubmit}
    >
      <div className="f-h2 f-500">
        {t('add_cash_modal.add_cash_to_your_account')}
      </div>
      <>
        <div className={s.content}>
          <div className={s.group}>
            <div>{t('add_cash_modal.specify_your_amount')}</div>
            <MoneyInput
              name="amount"
              value={values.amount}
              onChange={handleChange}
              error={touched.amount && errors.amount}
            />
          </div>

          <div className={s.group}>
            <div>{t('add_cash_modal.select_payment_method')}</div>
            <PaymentMethods
              amount={values.amount || 0}
              selected={values.method}
              onChange={method => setFieldValue('method', method.type)}
            />
          </div>

          <div className={s.footer}>
            <div className={s.total}>
              <div className="f-body">
                {t('add_cash_modal.total_payment_amount')}
              </div>
              <div className="f-h3 f-500">
                {calculatedAmount?.amount
                  ? prettyFloatMoney(calculatedAmount?.amount)
                  : EMPTY_DASH}
              </div>
            </div>
            <button
              type="submit"
              disabled={isFetching}
              className={classnames(r.black_large, s.btn_submit)}
            >
              {loading
                ? t('add_cash_modal.processing')
                : t('add_cash_modal.go_to_secure_payment')}
            </button>
            <div className={classnames(s.footer_desc, 'f-label')}>
              <div>{t('add_cash_modal.payment_processing_provided_by')}</div>
              <div>{t('add_cash_modal.currency_of_your_account')}</div>
              <div>
                {t('add_cash_modal.cash_accepted_only_from_bank_accounts', {
                  name: getFullname(user.first_name, user.last_name),
                })}
                .
              </div>
              <div>{t('add_cash_modal.note_about_bank_fees')}</div>
              <div>{t('add_cash_modal.transfer_of_funds')}</div>
            </div>
          </div>
        </div>
      </>
    </form>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {form}
    </ModalCenterLayout>
  );
};
