import classnames from 'classnames';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { useGetFundraisingStats } from '@/query/queries/investing/useGetFundraisingStats';
import { Preloader } from '@/components/Preloader';
import { useWindowSize } from '@/hooks/useWindowSize';
import { InvestingProgressBar } from '../../components/InvestingProgressBar';
import { MODAL_IDS } from '../../utils/constants';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.round_modal;

export const RoundModal = () => {
  const { modalIds } = useModal();
  const { closeModal } = useModal();
  const { data } = useGetFundraisingStats();
  const { isMobile } = useWindowSize();

  const content = (
    <div className={classnames(s.modal)}>
      <div className="f-h2 f-500">Invested to the round</div>
      <div className={s.content}>
        {data ? (
          <InvestingProgressBar
            current={data.currently_allocated}
            min={data.min_goal}
            max={data.total_goal}
            className={s.bar}
          />
        ) : (
          <Preloader style={{ height: '148px' }} />
        )}
      </div>
      <div className={s.footer}>
        <button
          type="button"
          className={classnames(r.black_large, s.btn_done)}
          onClick={() => closeModal(id)}
        >
          Done
        </button>
      </div>
    </div>
  );

  if (!modalIds[id]) return null;

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalCenterLayout>
  );
};
