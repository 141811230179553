export const PAYMENT_TYPES = {
  bank: 'bank',
  card: 'card',
  giropay: 'giropay',
  sepa_debit: 'sepa_debit',
  bancontact: 'bancontact',
  blik: 'blik',
  eps: 'eps',
  fpx: 'fpx',
  ideal: 'ideal',
  p24: 'p24',
  sofort: 'sofort',
};

export const PAYMENT_PROCESSING_DAYS = {
  sepa_debit: 5,
  sofort: 2,
};

export const PAYMENT_RESULT_STATUSES = {
  settled: 'settled',
  failed: 'failed',
  waiting_for_3ds_response: 'waiting_for_3ds_response',
};
