import classnames from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { BrandDetail } from '@/containers/BrandDetail';
import { Preloader } from '@/components/Preloader';
import { useWindowSize } from '@/hooks/useWindowSize';
import { useGetBrandDetails } from '@/query/queries/brand/useGetBrandDetails';
import { MODAL_IDS } from '../../utils/constants';
import s from './index.module.scss';

const id = MODAL_IDS.brand_detail_modal;

export const BrandDetailModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { closeModal } = useModal();
  const { isMobile } = useWindowSize();
  const [searchParams] = useSearchParams();
  const brandSlug = searchParams.get('brand');
  const { data } = useGetBrandDetails(brandSlug);
  const location = useLocation();
  const navigate = useNavigate();

  const isLoading = !data;

  const onClose = () => {
    navigate(location.pathname, { replace: true });
    closeModal(id);
  };

  const content = isLoading ? (
    <div className={classnames(s.modal)}>
      <Preloader style={{ height: '20vh' }} />
    </div>
  ) : data ? (
    <div className={classnames(s.modal, 'scr_modal_grid')}>
      <div className="f-h2 f-500">Brand details</div>
      <div className={s.content}>
        <BrandDetail data={data} className="modal" />
      </div>
    </div>
  ) : null;

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={onClose} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={onClose} showClose>
      {content}
    </ModalCenterLayout>
  );
};
