import { BankMethod } from './bank';
import { CardMethod } from './card';
import { OtherMethod } from './other';
import { PAYMENT_TYPES } from '../../constants';

export const PaymentMethod = props => {
  switch (props.data.type) {
    case PAYMENT_TYPES.bank:
      return <BankMethod {...props} />;

    case PAYMENT_TYPES.card:
      return <CardMethod {...props} />;

    default:
      return <OtherMethod {...props} />;
  }
};
