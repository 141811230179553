import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useSwipe } from '@/hooks/useSwipe';
import IconClose from '../../assets/icon_close.svg';
import s from './index.module.scss';

export const ModalSwipeLayout = ({ children, showClose, onClose }) => {
  const ref = useRef(null);

  useSwipe(ref, () => onClose(), { sensitivity: 150, axis: 'vertical' });

  return createPortal(
    <div className={s.wrapper}>
      <div className={s.backdrop} onClick={onClose} />
      <div className={s.content} ref={ref}>
        {children}
        {showClose && (
          <img
            src={IconClose}
            alt="close"
            width={24}
            height={24}
            onClick={onClose}
            className={s.icon}
          />
        )}
      </div>
    </div>,
    document.body,
  );
};
