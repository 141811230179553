import { createContext, useContext, useEffect } from 'react';
import { useModal } from '@/providers/modal';
import { INVESTMENT_STRATEGIES } from '@/helpers/constants';
import { calculateCash } from '@/helpers/utils';
import { useGetInvestingStatus } from '@/query/queries/investing/useGetInvestingStatus';
import { useSetInvestmentStrategy } from '@/query/queries/investing/useSetInvestmentStrategy';
import { useGetPreAllocateInfo } from '@/query/queries/investing/useGetPreAllocateInfo';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import { isExpiredResetDate } from '@/helpers/date';
import { MODAL_IDS } from './utils/constants';

const InvestingContext = createContext();

export const InvestingStateProvider = ({ children }) => {
  const { openSingleModal } = useModal();
  const { data: status } = useGetInvestingStatus();
  const { data: balance } = useGetBalance();
  const { mutateAsync: setStrategy } = useSetInvestmentStrategy();
  const { data: preAllocateInfo, refetch } = useGetPreAllocateInfo();

  const totalBalance = calculateCash(balance);

  const minInvestAmount = Math.max(
    preAllocateInfo?.bonus_amount || 0,
    status?.min_investment_amount || 0,
  );

  const onParticipate = async option => {
    if (!status) return;

    const hasMinCash = totalBalance >= minInvestAmount;

    // Set strategy if not selected yet;
    if (status.option === INVESTMENT_STRATEGIES.not_selected) {
      const res = await setStrategy({
        option,
        founders: [],
      });
      return res?.step === 2 && hasMinCash
        ? openSingleModal(MODAL_IDS.allocate_modal)
        : openSingleModal(MODAL_IDS.add_cash_modal);
    }

    // Open Allocation modal once strategy set
    if (status.step === 2) {
      return hasMinCash
        ? openSingleModal(MODAL_IDS.allocate_modal)
        : openSingleModal(MODAL_IDS.add_cash_modal);
    }

    // Open review modal if current step is 3
    if (status.step === 3) {
      return openSingleModal(MODAL_IDS.review_modal);
    }
  };

  const isParticipating =
    status?.is_active && !!status?.has_accepted_necessary_terms;

  const addOnly = !!(
    status?.step === 3 &&
    isParticipating &&
    isExpiredResetDate(status.editable_until)
  );

  useEffect(() => {
    status?.is_active && status?.step > 1 && refetch();
  }, [status]);

  return (
    <InvestingContext.Provider
      value={{
        status,
        isParticipating,
        addOnly,
        onParticipate,
      }}
    >
      {children}
    </InvestingContext.Provider>
  );
};

export const useHandleRoundStatus = () => useContext(InvestingContext);
