import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { cashService } from '../..//services/cashService';

export const useGetPaymentMethods = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.cash.payment_methods],
    queryFn: () =>
      cashService.paymentMethods().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
