import { useTranslation } from 'react-i18next';
import { EMPTY_DASH } from '@/helpers/constants';
import { prettyFloatMoney } from '@/helpers/utils';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import s from './index.module.scss';

export const Statistics = () => {
  const { t } = useTranslation('investing');
  const { data: balance } = useGetBalance();
  return (
    <div className={s.wrapper}>
      <div className={s.group}>
        <div className="f-body f-500 tl">
          {balance ? prettyFloatMoney(balance.total) : EMPTY_DASH}
        </div>
        <div className="f-label">{t('statistics.total')}</div>
      </div>
      <div className={s.group}>
        <div className="f-body f-500 tl">
          {balance ? prettyFloatMoney(balance.invested) : EMPTY_DASH}
        </div>
        <div className="f-label">{t('statistics.invested')}</div>
      </div>
      <div className={s.group}>
        <div className="f-body f-500 tl">
          {balance
            ? prettyFloatMoney(balance.available + balance.on_hold)
            : EMPTY_DASH}
        </div>
        <div className="f-label">{t('statistics.cash')}</div>
      </div>
    </div>
  );
};
