import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MODAL_IDS } from '../../utils/constants';
import { MobileTermView } from './LoanGroups/mobile';
import { DesktopTermView } from './LoanGroups/desktop';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

const id = MODAL_IDS.compare_groups_modal;

export const GroupsCompareModal = () => {
  const { modalIds } = useModal();
  return modalIds[id] && <Modal />;
};

const Modal = () => {
  const { closeModal } = useModal();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation('investing');

  const content = (
    <div className={classnames(s.modal, 'scr_modal_grid')}>
      <div className="f-h2 f-500">{t('compare_modal.investment_groups')}</div>
      <div className={s.content}>
        {isMobile ? <MobileTermView /> : <DesktopTermView />}
      </div>
      <div className={s.footer}>
        <button
          type="button"
          className={classnames(r.black_large, s.btn_done)}
          onClick={() => closeModal(id)}
        >
          {t('compare_modal.done')}
        </button>
      </div>
    </div>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={() => closeModal(id)} showClose>
      {content}
    </ModalCenterLayout>
  );
};
