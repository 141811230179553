import { useTranslation } from 'react-i18next';
import { USER_STATUSES } from '@/helpers/constants';
import { useGetFaq } from '@/query/queries/common/useGetFaq';
import { useGetMe } from '@/hooks/useGetMe';
import { Dropdown } from './dropdown';
import s from './index.module.scss';

export const MINIFAQ_SOURCES = {
  dashboard: 'Dashboard',
  investing: 'Investing',
  addCash: 'Add_cash',
  withdraw: 'Withdraw',
  loans: 'Loans',
  founders: 'Founders',
  investors: 'Investors',
  activity: 'Activity',
  profile: 'Profile',
  signUp: 'SignUp',
  affiliate: 'affiliate',
  bankDetails: 'Investor_bank_accounts',
};

export const Faq = ({ source, step, children }) => {
  const { t } = useTranslation();
  const { user } = useGetMe();
  const { data } = useGetFaq(
    { page: source, step },
    user?.status !== USER_STATUSES.suspended,
  );

  return (
    <div className={s.wrapper}>
      <h4 className="f-h2 f-500">{t('mini_faqs_faq')}</h4>
      {children}
      <div className={s.list}>
        {data
          ? data.faqs.map(item => (
              <Dropdown
                key={item.id}
                data={item}
                label={item.faq.question}
                innerContentHTML={item.faq.answer}
              />
            ))
          : null}
      </div>
    </div>
  );
};
