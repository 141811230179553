import React from 'react';

/**
 * Hook that handles swipe event of the passed ref
 *
 * @param ref ref of component
 * @param handler a handler function to be called when swipe triggered
 * @param options sensitivity: the number of pixels to swipe, axis: swipe direction
 */

export const useSwipe = (ref, handler, options) => {
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);
  const { sensitivity, axis } = options;

  React.useEffect(() => {
    ref.current?.addEventListener('touchstart', handleTouchStart);
    ref.current?.addEventListener('touchmove', handleTouchMove);
    ref.current?.addEventListener('touchend', handleTouchEnd);

    return () => {
      ref.current?.removeEventListener('touchstart', handleTouchStart);
      ref.current?.removeEventListener('touchmove', handleTouchMove);
      ref.current?.removeEventListener('touchend', handleTouchEnd);
    };
  }, [ref, handler]);

  const axios = axis === 'horizont' ? 'clientX' : 'clientY';

  function handleTouchStart(e) {
    setTouchStart(e.targetTouches[0][axios]);
  }

  function handleTouchMove(e) {
    setTouchEnd(e.targetTouches[0][axios]);
  }

  function handleResetAxis() {
    setTouchEnd(0);
    setTouchStart(0);
  }

  function handleTouchEnd() {
    if (axis === 'horizont' && touchStart - touchEnd > sensitivity) {
      handler();
    }
    if (axis === 'vertical' && touchEnd - touchStart > sensitivity) {
      handler();
    }
    handleResetAxis();
  }
};
