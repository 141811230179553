import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ImageFail from './fail.svg';
import r from '@/kit/RoundButton/button.module.scss';
import s from '../index.module.scss';

export const PaymentResultFailure = ({ onTry }) => {
  const { t } = useTranslation('investing');
  return (
    <div className={s.wrapper}>
      <div className={s.image}>
        <img src={ImageFail} alt="fail" />
      </div>
      <div className={classnames(s.error_text, 'f-body')}>
        {t('topup_fail_modal.payment_failed')}
      </div>
      <button
        type="button"
        className={classnames(r.black_large, s.btn_done)}
        onClick={onTry}
      >
        {t('topup_fail_modal.try_again')}
      </button>
    </div>
  );
};
