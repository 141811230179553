import { useEffect, useState } from 'react';
import { useGetBonusGroup } from '@/query/queries/marketing/useGetBonusGroup';
import { useAllocationStatus } from '../utils/useAllocationStatus';

// Hook to calculate bonus for allocation to GroupA.
export const useCalculateBonuses = (amount = 0) => {
  const [bonus, setBonus] = useState(0);

  const { data: bonusGroup } = useGetBonusGroup();

  const { allocatedGroupA, bonusAmount } = useAllocationStatus();

  useEffect(() => {
    if (bonusGroup) {
      const currentAmount = amount || allocatedGroupA;
      const calculatedBonus =
        Math.max(
          parseInt(
            (currentAmount - bonusAmount) / bonusGroup.investment_amount,
          ),
          0,
        ) * bonusGroup.bonus_amount;
      setBonus(calculatedBonus);
    }
  }, [amount, bonusGroup, allocatedGroupA, bonusAmount]);

  return { bonus };
};
