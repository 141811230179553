import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { INVESTMENT_STRATEGIES } from '@/helpers/constants';
import { useHandleRoundStatus } from '../../provider';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

export const InvestmentStrategies = () => {
  const { t } = useTranslation('investing');
  const { onParticipate } = useHandleRoundStatus();
  return (
    <div className={s.wrapper}>
      <div className="f-h2 f-500">{t('strategies.investment_strategies')}</div>
      <div className={s.desc}>{t('strategies.you_have_two_options')}</div>
      <div className={s.cards}>
        <div className={s.card}>
          <div className="f-h3 f-500">
            {t('strategies.standard_allocation')}
          </div>
          <div className="f-body" style={{ marginTop: '8px' }}>
            {t('strategies.your_cash_is_proportionally_allocated')}
          </div>
          <button
            type="button"
            className={classnames(s.button, r.black_small)}
            onClick={() => onParticipate(INVESTMENT_STRATEGIES.standard)}
          >
            {t('strategies.participate_in_the_round')}
          </button>
        </div>
        <div className={classnames(s.card, s.card_disabled)}>
          <div className="f-h3 f-500">
            {t('strategies.priority_allocation_unavailable')}
          </div>
          <div className="f-body" style={{ marginTop: '8px' }}>
            {t('strategies.allocate_more_to_selected_brands')}
          </div>
          <button
            type="button"
            className={classnames(s.button, r.black_small)}
            onClick={() => onParticipate(INVESTMENT_STRATEGIES.priority)}
            disabled
          >
            {t('strategies.participate_in_the_round')}
          </button>
        </div>
      </div>
    </div>
  );
};
