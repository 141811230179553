import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BrandDetail } from '@/containers/BrandDetail';
import { Preloader } from '@/components/Preloader';
import { BackButton } from '@/components/UI/Buttons';
import { canGoBack } from '@/helpers/utils';
import { APP_LINKS } from '@/helpers/links';
import { useGetBrandDetails } from '@/query/queries/brand/useGetBrandDetails';
import s from './index.module.scss';

const StartupDetailPage = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { t } = useTranslation('brands');

  const { data, isLoading } = useGetBrandDetails(slug);

  const onBack = () => {
    canGoBack() ? navigate(-1) : navigate(APP_LINKS.startups);
  };

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <div className={s.back}>
          <BackButton onClick={onBack} />
        </div>
        {isLoading ? (
          <Preloader className={s.preloader} />
        ) : data ? (
          <BrandDetail data={data} />
        ) : (
          <div className={s.empty}>{t('startup_detil.check_connection')}</div>
        )}
      </div>
    </div>
  );
};

export default StartupDetailPage;
