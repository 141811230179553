import classnames from 'classnames';
import { prettyFloatMoney } from '@/helpers/utils';
import { ClampText } from '@/kit/ClampText';
import { EMPTY_DASH } from '@/helpers/constants';
import s from './desktop.module.scss';

export const DesktopBrandCard = ({ brand, onClick }) => {
  return (
    <div className={classnames(s.card, 'hov')} onClick={onClick}>
      <div className={s.image}>
        <img
          src={brand.promo_image?.file.replace('test-', '')}
          alt={brand.name}
        />
      </div>
      <div className={s.content}>
        <div className={s.header}>
          <div className={classnames(s.name, 'f-body', 'f-500')}>
            {brand.name}
          </div>
          <div className={classnames(s.goal, 'f-label')}>
            Goal:{' '}
            <span>
              {brand.fundraising_goal
                ? prettyFloatMoney(brand.fundraising_goal)
                : EMPTY_DASH}
            </span>
          </div>
        </div>
        <div className={classnames(s.info, 'f-label')}>
          {!!brand.sectors?.length &&
            brand.sectors.map(sector => (
              <div key={sector.id}>{sector.name}</div>
            ))}
          {brand.foundation_year && <div>Est. {brand.foundation_year}</div>}
          {brand.country && <div>{brand.country}</div>}
        </div>
        <ClampText
          text={brand.description}
          lineClampNumber={2}
          className={s.desc}
        />
      </div>
    </div>
  );
};
