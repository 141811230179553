import { useInfiniteQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { investingService } from '../../services/investingService';

export const useGetParticipatingBrands = () => {
  return useInfiniteQuery({
    refetchOnWindowFocus: false,
    queryKey: [QUERY_KEYS.investing.participating_brands],
    queryFn: ({ pageParam = 1 }) =>
      investingService
        .participatingBrands({ page: pageParam, limit: 10 })
        .then(response => {
          const { status, data } = response;
          if (status === 200) {
            return data;
          }
          return null;
        }),
    getNextPageParam: (lastPage, allPages) => {
      const maxPage = lastPage.total_pages;
      const nextPage = allPages.length + 1;
      return nextPage <= maxPage ? nextPage : undefined;
    },
  });
};
