import { useLocation, useNavigate } from 'react-router-dom';
import { useModal } from '@/providers/modal';
import { useWindowSize } from '@/hooks/useWindowSize';
import { MODAL_IDS } from '../../../utils/constants';
import { MobileBrandCard } from './mobile';
import { DesktopBrandCard } from './desktop';

export const BrandCard = props => {
  const { openModal } = useModal();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();

  const onBrandClick = brand => {
    openModal(MODAL_IDS.brand_detail_modal);
    navigate(location.pathname + `?brand=${brand.slug}`);
  };

  return isMobile ? (
    <MobileBrandCard onClick={() => onBrandClick(props.brand)} {...props} />
  ) : (
    <DesktopBrandCard onClick={() => onBrandClick(props.brand)} {...props} />
  );
};
