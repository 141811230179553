import moment from 'moment';
import momentLocale from 'moment/min/moment-with-locales';
import momentTZ from 'moment-timezone';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { prettyDate } from '@/helpers/utils';
import { TIMEZONE_ESTONIA } from './constants';
import {
  DATE_FORMAT_D,
  DATE_FORMAT_D_MMMM,
  DATE_FORMAT_DD_MMMM,
  DATE_FORMAT_DD_MMMM_YYYY,
  DATE_FORMAT_M,
} from './common';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);

export const roundStarts = (date, format = 'DD MMM, HH:mm') => {
  if (!date) {
    return '';
  }
  const timezone = moment.tz.guess();
  const roundTime = momentTZ.tz(date, TIMEZONE_ESTONIA).add(12, 'hours');
  return `${momentTZ.tz(roundTime, timezone).format(format)} GMT`;
};

export const resultProcess = (date, format = 'DD MMM') => {
  return date ? moment(date).add(1, 'day').format(format) : '';
};

export const remainingTime = date => {
  return (
    <span>
      <ReactTimeAgo date={date} locale="en-US" timeStyle="twitter" />
    </span>
  );
};

export const resetInvestingExpireDate = date =>
  momentLocale(date).format(DATE_FORMAT_DD_MMMM_YYYY);

export const isExpiredResetDate = date => {
  if (!date) {
    return false;
  }
  return moment(date).isBefore();
};

export const isBefore = targetDate => moment().isBefore(targetDate);

export const isAfter = targetDate => moment().isAfter(targetDate);

export const getFundraisingDateRange = ({ startDate, endDate }) => {
  const emptyMessage = 'Dates to be announced';
  if (!startDate && !endDate) return emptyMessage;

  if (startDate && !endDate) return prettyDate(startDate, DATE_FORMAT_DD_MMMM);

  if (startDate && endDate) {
    if (
      prettyDate(startDate, DATE_FORMAT_M) ===
      prettyDate(endDate, DATE_FORMAT_M)
    )
      return `${prettyDate(startDate, DATE_FORMAT_D)} - ${prettyDate(endDate, DATE_FORMAT_D_MMMM)}`;

    return `${prettyDate(startDate, DATE_FORMAT_D_MMMM)} - ${prettyDate(endDate, DATE_FORMAT_D_MMMM)}`;
  }

  return emptyMessage;
};

export const daysLeft = targetDate => {
  const now = new Date();
  const target = new Date(targetDate);

  // Calculate difference in milliseconds
  const diff = target - now;

  // Convert milliseconds to days
  const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

  if (days === 1) {
    return '1 day';
  }

  return days > 0 ? `${days} days` : '';
};
