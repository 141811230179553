import * as Yup from 'yup';

const MIN_AMOUNT = 1;
const MAX_AMOUNT = 5000;

export const VALIDATION_SCHEMA = Yup.object({
  amount: Yup.number()
    .min(MIN_AMOUNT, `Minimum amount is ${MIN_AMOUNT}`)
    .max(MAX_AMOUNT, 'Maximum limit exceeds')
    .required('This field is required'),
  method: Yup.string().required('This field is required'),
});
