import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import Notify from '@/components/Notification';
import { getMessage } from '@/helpers/utils';
import { investingService } from '../../services/investingService';

export const useSetAllocation = onSuccess => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: payload =>
      investingService.setAllocation(payload).then(response => {
        const { status, data } = response;
        if (status === 200) {
          queryClient.setQueryData(
            [QUERY_KEYS.investing.pre_allocate_info],
            data,
          );
          onSuccess(data);
          return data;
        }

        if (status === 422) {
          Notify.error({ text: getMessage(data) });
        }

        console.error(data?.message);
      }),
  });
};
