import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getMessage } from '@/helpers/utils';
import {
  GET_INCREASED_INCOME_STATUS,
  GET_INVESTING_RESULTS,
} from '../types/investTypes';

export const getInvestingResultsRequest = () => ({
  type: GET_INVESTING_RESULTS.REQUEST,
});
export const getInvestingResultsSuccess = payload => ({
  type: GET_INVESTING_RESULTS.SUCCESS,
  payload,
});
export const getInvestingResultsFailure = error => ({
  type: GET_INVESTING_RESULTS.FAILURE,
  error,
});

export const getIncreasedIncomeStatusRequest = () => ({
  type: GET_INCREASED_INCOME_STATUS.REQUEST,
});
export const getIncreasedIncomeStatusSuccess = payload => ({
  type: GET_INCREASED_INCOME_STATUS.SUCCESS,
  payload,
});
export const getIncreasedIncomeStatusFailure = error => ({
  type: GET_INCREASED_INCOME_STATUS.FAILURE,
  error,
});

export const getInvestingResults = () => {
  return dispatch => {
    dispatch(getInvestingResultsRequest());
    return axios
      .get(Pathes.Investing.results)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getInvestingResultsSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getInvestingResultsFailure(e.message)));
  };
};

export const getIncreasedIncomeStatus = () => {
  return dispatch => {
    dispatch(getIncreasedIncomeStatusRequest());
    return axios
      .get(Pathes.Investing.increasedIncome)
      .then(response => {
        const { status, data } = response;
        const message = getMessage(data);
        if (status === 200) {
          dispatch(getIncreasedIncomeStatusSuccess(data));
          return { data, success: true };
        }

        throw new Error(message);
      })
      .catch(e => dispatch(getIncreasedIncomeStatusFailure(e.message)));
  };
};
