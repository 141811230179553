import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { marketingService } from '../../services/marketingService';

export const useGetReferralCode = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.marketing.referral_code],
    queryFn: () =>
      marketingService.referralCode().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
