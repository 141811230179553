import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { investingService } from '../../services/investingService';

export const useGetInvestedCountries = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.investing.invested_countries],
    queryFn: () =>
      investingService.investedCountries().then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
