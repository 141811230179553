import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { prettyFloatMoney, prettyMoneyHybrid } from '@/helpers/utils';
import { EMPTY_DASH } from '@/helpers/constants';
import './index.scss';

export const CashDetailBox = ({ balance, className }) => {
  const { t } = useTranslation('dashboard');

  return (
    <div className={classnames('cash_detail_box', className)}>
      <div className="cash_detail_box__item">
        <div className="cash_detail_box__item_title f-16 f-500">
          {t('cash_detail_available_cash')}
        </div>
        <div className="cash_detail_box__item_value tl f-26 f-500">
          {balance ? prettyMoneyHybrid(balance.available) : EMPTY_DASH}
        </div>
      </div>
      <div className="cash_detail_box__item">
        <div className="cash_detail_box__item_title f-16 f-500">
          {t('cash_detail_allocated_cash')}
        </div>
        <div className="cash_detail_box__item_value tl f-26 f-500">
          {balance ? prettyFloatMoney(balance.on_hold) : EMPTY_DASH}
        </div>
      </div>
      {balance?.available_bonus_amount !== null && (
        <div className="cash_detail_box__item">
          <div className="cash_detail_box__item_title f-16 f-500">
            {t('cash_detail_scramble_bonus')}
          </div>
          <div className="cash_detail_box__item_value tl f-26 f-500">
            {balance
              ? prettyMoneyHybrid(balance.available_bonus_amount)
              : EMPTY_DASH}
          </div>
        </div>
      )}
    </div>
  );
};
