import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { APP_LINKS } from '@/helpers/links';
import { getUserProfile } from '@/store/actions/profileActions';
import './index.scss';

const TABS = t => [
  { label: t('add_cash'), path: APP_LINKS.addFunds },
  { label: t('withdraw'), path: APP_LINKS.withdraw },
  { label: t('bank_accounts'), path: APP_LINKS.bankAccounts },
];

const FundsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('funds');

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (location.pathname === APP_LINKS.cash) {
      navigate(APP_LINKS.addFunds, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <div className="funds-page">
      <div className="container-inner">
        <h1 className="funds-page__title f-500 f-42">{t('cash')}</h1>
        <div className="funds-page__links">
          {TABS(t).map(tab => (
            <NavLink
              key={tab.path}
              to={tab.path}
              className={({ isActive }) =>
                `funds-page__link ${isActive ? 'funds-page__link-selected' : ''}`
              }
            >
              {tab.label}
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default FundsPage;
