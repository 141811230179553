import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { commonService } from '../../services/commonService';

export const useGetFaq = (params, enabled) => {
  return useQuery({
    queryKey: [QUERY_KEYS.common.faq, params],
    queryFn: () =>
      commonService.faq(params).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
    enabled,
  });
};
