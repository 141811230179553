import { useTranslation } from 'react-i18next';
import { useCalculateBonuses } from '@/pages/InvestingPage/utils/useCalculateBonuses';
import { prettyFloatMoney } from '@/helpers/utils';
import { useGetBonusGroup } from '@/query/queries/marketing/useGetBonusGroup';
import s from './index.module.scss';

export const BonusForAllocation = ({ amount = 0 }) => {
  const { data: bonusGroup } = useGetBonusGroup();
  const { bonus } = useCalculateBonuses(amount);
  const { t } = useTranslation('investing');

  if (!bonusGroup) return null;

  return (
    <div className={s.wrapper}>
      <div className="f-body">
        {t('bonus_allocation.get_bonus', {
          bonus_amount: prettyFloatMoney(bonusGroup.bonus_amount),
          investment_amount: prettyFloatMoney(bonusGroup.investment_amount),
        })}
      </div>
      <div className="f-label">
        {t('bonus_allocation.total_bonus', { bonus: prettyFloatMoney(bonus) })}
      </div>
    </div>
  );
};
