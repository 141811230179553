export const MODAL_IDS = {
  allocate_modal: 'allocate_modal',
  add_cash_modal: 'add_cash_modal',
  compare_groups_modal: 'compare_groups_modal',
  round_modal: 'round_modal',
  country_modal: 'country_modal',
  brands_modal: 'brands_modal',
  review_modal: 'review_modal',
  bank_requisites: 'bank_requisites',
  top_up_result_modal: 'top_up_result_modal',
  brand_detail_modal: 'brand_detail_modal',
};
