import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { daysLeft, getFundraisingDateRange } from '@/helpers/date';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { EMPTY_DASH } from '@/helpers/constants';
import IconCalendar from '../../assets/icons/icon_calendar.svg';
import IconShopping from '../../assets/icons/icon_shopping.svg';
import IconGlobe from '../../assets/icons/icon_globe.svg';
import IconTarget from '../../assets/icons/icon_target.svg';
import s from './index.module.scss';

export const NextRoundInfo = () => {
  const { data: dates } = useGetFundraisingDates();
  const { t } = useTranslation('investing');

  return (
    <div className={s.wrapper}>
      <div className={classnames(s.title, 'f-h2 f-500')}>
        {t('next_round_info.next_round')}
      </div>
      <div className={s.cards_outer}>
        <div className={s.cards}>
          <div className={s.card} style={{ cursor: 'unset' }}>
            <img src={IconCalendar} alt="date" width={24} height={24} />
            <div className={s.label}>{t('next_round_info.date')}</div>
            <div className="f-body f-500">
              {dates
                ? getFundraisingDateRange({
                    startDate: dates.start_date,
                    endDate: dates.end_date,
                  })
                : EMPTY_DASH}
            </div>
          </div>
          <div className={s.card} style={{ cursor: 'unset' }}>
            <img src={IconTarget} alt="goal" width={24} height={24} />
            <div className={s.label}>
              {t('next_round_info.fundraising_goal')}
            </div>
            <div className="f-body f-500">
              {dates
                ? t('next_round_info.announced_in', {
                    days: daysLeft(dates.start_date),
                  })
                : EMPTY_DASH}
            </div>
          </div>
          <div className={s.card} style={{ cursor: 'unset' }}>
            <img src={IconShopping} alt="brands" width={24} height={24} />
            <div className={s.label}>
              {t('next_round_info.brands_fundraising_money')}
            </div>
            <div className="f-body f-500">
              {dates
                ? t('next_round_info.announced_in', {
                    days: daysLeft(dates.start_date),
                  })
                : EMPTY_DASH}
            </div>
          </div>
          <div className={s.card} style={{ cursor: 'unset' }}>
            <img src={IconGlobe} alt="date" width={24} height={24} />
            <div className={s.label}>{t('next_round_info.investors')}</div>
            <div className="f-body f-500">
              {dates
                ? t('next_round_info.announced_in', {
                    days: daysLeft(dates.start_date),
                  })
                : EMPTY_DASH}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
