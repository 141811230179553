import {
  GET_EARNINGS,
  GET_HAPPY_FOUNDERS,
  GET_LOAN_GROUPS,
} from '../actions/actionTypes';
import { METADATA } from '@/helpers/metadata';

const initialState = {
  earnings: null,
  happyFounders: null,
  loanGroups: { ...METADATA.default, data: null },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EARNINGS:
      return { ...state, earnings: action.earnings };
    case GET_HAPPY_FOUNDERS:
      return { ...state, happyFounders: action.founders };
    case GET_LOAN_GROUPS.REQUEST:
      return {
        ...state,
        loanGroups: { ...state.loanGroups, ...METADATA.request },
      };
    case GET_LOAN_GROUPS.SUCCESS:
      return {
        ...state,
        loanGroups: {
          ...state.loanGroups,
          ...METADATA.success,
          data: action.payload,
        },
      };
    case GET_LOAN_GROUPS.FAILURE:
      return {
        ...state,
        loanGroups: {
          ...state.loanGroups,
          ...METADATA.error,
          error: action.error,
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
