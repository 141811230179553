import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGetAllocatedRepaymentSchedule } from '@/query/queries/investing/useGetAllocatedRepaymentSchedule';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { RepaymentSchedule } from './RepaymentSchedule';
import { EarningSchedule } from './EarningSchedule';
import s from './index.module.scss';

const TABS = {
  earnings: 'earnings',
  repayments: 'repayments',
};

export const EarningsAndRepayments = () => {
  const { t } = useTranslation('investing');
  const [tab, setTab] = useState(TABS.earnings);
  const { data: fundraisingDates } = useGetFundraisingDates();
  const activeFundraisingId =
    fundraisingDates?.is_active && fundraisingDates?.id;
  const { data, refetch } =
    useGetAllocatedRepaymentSchedule(activeFundraisingId);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.tabs}>
        <div
          className={classnames(
            s.tab,
            tab === TABS.earnings && s.tab_active,
            'hov',
          )}
          onClick={() => setTab(TABS.earnings)}
        >
          {t('schedules.earnings_schedule')}
        </div>
        <div
          className={classnames(
            s.tab,
            tab === TABS.repayments && s.tab_active,
            'hov',
          )}
          onClick={() => setTab(TABS.repayments)}
        >
          {t('schedules.repayment_schedule')}
        </div>
      </div>
      {tab === TABS.earnings && <EarningSchedule data={data} />}
      {tab === TABS.repayments && <RepaymentSchedule data={data} />}
    </div>
  );
};
