import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useModal } from '@/providers/modal';
import IconPlus from '../../assets/icons/icon_plus.svg';
import { MODAL_IDS } from '../../utils/constants';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

export const RoundPrepare = () => {
  const { openSingleModal } = useModal();
  const { t } = useTranslation('investing');

  return (
    <div className={s.wrapper}>
      <div className="f-body" style={{ flexGrow: 1 }}>
        {t('round_prepare.be_prepared_to_next_round')}
      </div>
      <button
        type="button"
        className={classnames(r.black_small, s.btn_done)}
        onClick={() => openSingleModal(MODAL_IDS.add_cash_modal)}
      >
        {t('round_prepare.add_money')}
        <img src={IconPlus} alt="plus" width={24} height={24} />
      </button>
    </div>
  );
};
