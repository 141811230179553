import classnames from 'classnames';
import s from './index.module.scss';

const TYPES = {
  info: 'info',
  danger: 'danger',
  normal: 'normal',
  warning: 'warning',
  success: 'success',
};

export const Alert = ({
  type,
  icon,
  title,
  description,
  action,
  children,
  className,
}) => {
  return (
    <div className={classnames(s.block, type, className)}>
      {icon}
      <div className={s.content}>
        {children || (
          <>
            <div className={s.title}>{title}</div>
            {description && <div className={s.desc}>{description}</div>}
            {action}
          </>
        )}
      </div>
    </div>
  );
};
