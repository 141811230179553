import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { LOAN_GROUPS } from '@/helpers/constants';
import { useAllocationStatus } from '../../../utils/useAllocationStatus';
import { Table } from './table';
import s from './index.module.scss';

export const RepaymentSchedule = ({ data }) => {
  const { t } = useTranslation('investing');
  const { t: tConstants } = useTranslation('constants');
  const { allocatedGroupA, allocatedGroupB } = useAllocationStatus();

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const getGroup = () => {
    if (allocatedGroupA && allocatedGroupB) {
      return `${LOAN_GROUPS_T.conservative.full_name} and ${LOAN_GROUPS_T.moderate.full_name}`;
    }
    if (allocatedGroupA) {
      return LOAN_GROUPS_T.conservative.full_name;
    }
    if (allocatedGroupB) {
      return LOAN_GROUPS_T.moderate.full_name;
    }
  };

  const groups = getGroup();

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="f-16-24">{t('repayment_schedule.may_be_changed')}</div>
      <div className={s.schedule}>
        <div className={s.schedule_title}>
          {t('repayment_schedule.loan', { groups })}
        </div>
        <Table data={data} />
      </div>
      <div className={classnames(s.hint, 'f-16 f-400')}>
        {t('repayment_schedule.is_subject_to_changes')}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          {t('repayment_schedule.loan_terms')}
        </MyLink>
        .
      </div>
    </>
  );
};
