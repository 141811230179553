import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getQuery } from '@/helpers/utils';

export const cashService = {
  balance: () => axios.get(Pathes.Dashboard.balance),
  paymentMethods: () => axios.get(Pathes.LHV.methods),
  calculateTotalAmount: params =>
    axios.get(Pathes.Funds.calculate + getQuery(params)),
  paymentResult: params =>
    axios.get(Pathes.LHV.paymentSession + getQuery(params)),
};
