import * as Yup from 'yup';
import { prettyFloatMoney } from '@/helpers/utils';
import i18n from '@/i18n';

const { t } = i18n;

export const getValidationSchema = bonusAmount => {
  return Yup.object({
    group_a_amount: Yup.number()
      .min(
        bonusAmount,
        t('investing:allocate_modal.validation_min_bonus', {
          amount: prettyFloatMoney(bonusAmount),
        }),
      )
      .required(t('investing:allocate_modal.required_field')),
    group_b_amount: Yup.number().required(
      t('investing:allocate_modal.required_field'),
    ),
  });
};
