import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import { ArticleCard } from './components/ArticleCard';
import { ArticleMainCard } from './components/ArticleMainCard';
import { getBlogArticles } from '@/store/actions/blogActions';
import { Preloader } from '@/components/Preloader';
import { Pagination } from '@/components/Pagination';
import s from './index.module.scss';

const BlogPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data, loading } = useSelector(state => state.blogStore.blogArticles);
  const { t } = useTranslation('blog');
  const query = qs.parse(location.search.replace('?', ''));
  const [params, setParams] = useState({
    page: Number(query?.page) || 1,
    limit: Number(query?.limit) || 10,
  });

  useQueryParams(params, setParams);

  useEffect(() => {
    dispatch(getBlogArticles(params));
  }, [params.page]);

  const isEmpty = !(data && data.list.length);

  const onPageChange = e => {
    // const newOffset = (event.selected * itemsPerPage) % items.length;
    setParams(prev => ({ ...prev, page: e.selected + 1 }));
  };

  return (
    <div className={s.wrapper}>
      <div className="container-inner">
        <div className={s.content}>
          <h1 className={classnames('f-h1', 'f-500', s.title)}>{t('title')}</h1>
          <p className={classnames('text_subtitle_1', s.subtitle)}>
            {t('subtitle')}
          </p>

          {loading && !data ? (
            <Preloader />
          ) : isEmpty ? (
            <div>{t('no_blog')}</div>
          ) : (
            <>
              {data.list[0] && <ArticleMainCard article={data?.list[0]} />}

              {data.list.length > 1 && (
                <div className={s.cards}>
                  {data.list.slice(1, data.list.length).map(article => (
                    <ArticleCard key={article.slug} article={article} />
                  ))}
                </div>
              )}

              <Pagination
                currentPage={params.page - 1}
                totalPages={data.total_pages}
                onPageChange={onPageChange}
                className={s.pagination}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
