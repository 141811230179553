import {
  SET_PARTICIPATING_STARTUPS,
  SET_INACTIVE_STARTUPS,
} from '../types/startupTypes';

const initialState = {
  participatingStartups: null,
  inactiveStartups: null,
};

const startupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARTICIPATING_STARTUPS:
      return { ...state, participatingStartups: action.payload };
    case SET_INACTIVE_STARTUPS:
      return { ...state, inactiveStartups: action.payload };
    default:
      return state;
  }
};

export default startupReducer;
