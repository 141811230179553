import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  calculateCash,
  getFundraisingState,
  prettyMoneyHybrid,
} from '@/helpers/utils';
import { CashDetailBox } from '../CashDetailBox';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { SpecTriangleIcon } from '@/components/UI/Icons';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import IconStar from '@/assets/icons/icon_bonus_star_20.svg';
import { APP_LINKS } from '@/helpers/links';
import { MyLink } from '@/components/MyLink';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import { EMPTY_DASH } from '@/helpers/constants';
import './index.scss';

export const TopHeader = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation('header');
  const { data: fundraisingDates } = useGetFundraisingDates();
  const { data: balance } = useGetBalance();
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  const ref = useRef();
  useOutsideClick(ref, () => setShow(false), show);

  const fundraisingState = getFundraisingState(fundraisingDates);
  const cash = calculateCash(balance);

  return (
    <div className="top-header">
      <div className="container-inner">
        <div className="top-header__inner">
          <p className="top-header__dates f-16 f-400">{fundraisingState}</p>
          <div className="top-header__summary">
            <div className="top-header__item">
              <span className="f-16 f-400">{t('header_total')}:</span>
              <span className="f-500">
                {balance ? prettyMoneyHybrid(balance.total) : EMPTY_DASH}
              </span>
            </div>
            <div className="top-header__item">
              <span className="f-16 f-400">{t('header_invested')}:</span>
              <span className="f-500">
                {balance ? prettyMoneyHybrid(balance.invested) : EMPTY_DASH}
              </span>
            </div>
            <div
              className="top-header__item cash row"
              ref={ref}
              onClick={() => setShow(!show)}
            >
              <span className="f-16 f-400">{t('header_cash')}:</span>
              <span className="f-500" style={{ marginLeft: '5px' }}>
                {balance ? prettyMoneyHybrid(cash) : EMPTY_DASH}
              </span>
              {show && (
                <div
                  className="top-header__popup"
                  onClick={e => e.stopPropagation()}
                >
                  <SpecTriangleIcon className="top-header__popup_triangle" />
                  <CashDetailBox balance={balance} />
                </div>
              )}
            </div>
            <MyLink
              className="top-header__invite hov"
              href={APP_LINKS.affiliate}
            >
              {t('header_invite_friends')}{' '}
              {isActiveReferralBanner && (
                <img width={20} height={20} src={IconStar} alt="icon" />
              )}
            </MyLink>
            <LanguageSwitcher className="top-header__language_switcher" />
          </div>
        </div>
      </div>
    </div>
  );
};
