import axios from '@/axios-api';
import Pathes from '@/helpers/pathes';
import { getQuery } from '@/helpers/utils';

export const investingService = {
  status: () => axios.get(Pathes.Investing.status),
  repaymentSchedule: fundraisingId =>
    axios.get(Pathes.Investing.repaymentSchedule(fundraisingId)),
  preAllocateInfo: () => axios.get(Pathes.Funds.preAllocateInfo),
  fundraisingStats: () => axios.get(Pathes.Investing.fundraisingStats),
  investedCountries: () =>
    axios.get(Pathes.Investing.fundraisingCountriesStats),
  participatingBrands: params =>
    axios.get(Pathes.Company.participating + getQuery(params)),
  confirmInvestment: payload =>
    axios.post(Pathes.Investing.investmentConfirmation, payload),
  fundraisingDates: () => axios.get(Pathes.Investing.fundraisingDates),
  setStrategy: payload => axios.post(Pathes.Investing.strategy, payload),
  setAllocation: payload => axios.post(Pathes.Funds.preAllocateInfo, payload),
  resetInvesting: () => axios.post(Pathes.Investing.doResetInvestment),
  allocateInfo: () => axios.get(Pathes.Funds.allocateInfo),
  results: () => axios.get(Pathes.Investing.results),
};
