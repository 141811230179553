import { LOAN_GROUPS_IDS } from '@/helpers/constants';
import { prettyFloatMoney, prettyMoney } from '@/helpers/utils';
import i18n from '@/i18n';

const { t } = i18n;

const MIN_INVESTMENT_AMOUNT = 10;

export const validate = (values, groups, allocatedA, allocatedB) => {
  const groupA = Number(values.group_a_amount) || 0;
  const groupB = Number(values.group_b_amount) || 0;

  const remainingA =
    allocatedA + groups[LOAN_GROUPS_IDS.conservative.id].remaining_amount || 0;
  const remainingB =
    allocatedB + groups[LOAN_GROUPS_IDS.moderate.id].remaining_amount || 0;

  const errors = {};

  if (groupA > remainingA) {
    errors.group_a_amount =
      remainingA === 0
        ? t('investing:allocate_modal.remaining_amount_reached')
        : t('investing:allocate_modal.only_left_in_group', {
            amount: prettyFloatMoney(remainingA),
          });
  }

  if (groupB > remainingB) {
    errors.group_b_amount =
      remainingB === 0
        ? t('investing:allocate_modal.remaining_amount_reached')
        : t('investing:allocate_modal.only_left_in_group', {
            amount: prettyFloatMoney(remainingB),
          });
  }

  const total = groupA + groupB;
  if (total < MIN_INVESTMENT_AMOUNT) {
    errors.common = t('investing:allocate_modal.min_investment_amount', {
      amount: prettyMoney(MIN_INVESTMENT_AMOUNT),
    });
  }

  return errors;
};
