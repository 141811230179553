import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useModal } from '@/providers/modal';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import { DATE_FORMAT_DD_MMMM_YYYY } from '@/helpers/common';
import { useHandleRoundStatus } from '@/pages/InvestingPage/provider';
import { EMPTY_DASH, INVESTMENT_STRATEGIES } from '@/helpers/constants';
import { useAllocationStatus } from '../../utils/useAllocationStatus';
import { useCalculateEarnings } from '../../utils/useCalculateEarnings';
import { useCalculateBonuses } from '../../utils/useCalculateBonuses';
import { MODAL_IDS } from '../../utils/constants';
import { EarningsAndRepayments } from '../schedules';
import IconEdit from '../../assets/icons/icon_edit.svg';
import IconAdd from '../../assets/icons/icon_plus.svg';
import r from '@/kit/RoundButton/button.module.scss';
import s from './index.module.scss';

export const Participation = () => {
  const { openSingleModal } = useModal();
  const { t } = useTranslation('investing');
  const { status, addOnly } = useHandleRoundStatus();
  const { allocatedGroupA, allocatedGroupB, totalAllocated } =
    useAllocationStatus();
  const { plannedEarning, apr } = useCalculateEarnings();
  const { bonus } = useCalculateBonuses();

  const onManage = () => {
    openSingleModal(MODAL_IDS.allocate_modal);
  };

  return (
    <div className={s.wrapper}>
      <div className="f-h2 f-500">{t('participation.my_participation')}</div>
      <div className={classnames(s.strategy, 'f-body')}>
        <div>{t('participation.strategy')}</div>
        <div>
          <>
            {status.option === INVESTMENT_STRATEGIES.not_selected && (
              <span>{EMPTY_DASH}</span>
            )}
            {status.option === INVESTMENT_STRATEGIES.standard && (
              <span>{t('participation.standard_strategy')}</span>
            )}
            {status.option === INVESTMENT_STRATEGIES.priority && (
              <span>{t('participation.priority_strategy')}</span>
            )}
          </>
        </div>
      </div>
      <div className={s.header}>
        <div className="f-h3 f-500">{t('participation.allocation')}</div>
        {addOnly ? (
          <button type="button" className={r.black_small} onClick={onManage}>
            {t('participation.allocate_more')}
            <img src={IconAdd} alt="pen" width={24} height={24} />
          </button>
        ) : (
          <button type="button" className={r.black_small} onClick={onManage}>
            {t('participation.manage')}
            <img src={IconEdit} alt="pen" width={24} height={24} />
          </button>
        )}
      </div>
      <div className={classnames(s.allocation, 'f-body')}>
        <div className={s.allocation_row}>
          <div>{t('participation.group_a')}</div>
          <div>{prettyFloatMoney(allocatedGroupA)}</div>
        </div>
        <div className={s.allocation_row}>
          <div>{t('participation.group_b')}</div>
          <div>{prettyFloatMoney(allocatedGroupB)}</div>
        </div>
        <div className={s.allocation_row}>
          <div>
            {t('participation.bonuses')}
            <span style={{ color: 'var(--Grey-500, #908A94)' }}>
              {t('participation.bonuses_note')}
            </span>
          </div>
          <div>{prettyFloatMoney(bonus)}</div>
        </div>
        <div className={s.allocation_row}>
          <div>{t('participation.total_allocated')}</div>
          <div>{prettyFloatMoney(totalAllocated)}</div>
        </div>
        <div className="f-label" style={{ color: 'var(--Grey-500, #908A94)' }}>
          {t('participation.modify_investment_settings', {
            date: prettyDate(status.editable_until, DATE_FORMAT_DD_MMMM_YYYY),
          })}
        </div>
      </div>
      <div className="f-h3 f-500">{t('participation.earnings')}</div>
      <div className={classnames(s.earnings, 'f-body')}>
        <div className={s.earnings_row}>
          <div>{t('participation.planned_apr')}</div>
          <div>{apr}%</div>
        </div>
        <div className={s.earnings_row}>
          <div>{t('participation.planned_earnings')}</div>
          <div>
            {t('participation.up_to', {
              amount: prettyFloatMoney(plannedEarning),
            })}
          </div>
        </div>
      </div>
      <EarningsAndRepayments />
    </div>
  );
};
