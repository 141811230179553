import { useMemo, useState } from 'react';
import classnames from 'classnames';
import { parseWithLinks } from '@/helpers/utils';
import IconArrow from './arrow.svg';
import s from './index.module.scss';

export const Dropdown = ({
  label,
  isActive,
  children,
  innerContentHTML,
  data,
  disableIcon = false,
}) => {
  const [show, setShow] = useState(!!isActive);

  const text = useMemo(
    () => parseWithLinks(innerContentHTML),
    [innerContentHTML],
  );

  const onHandleShowMenu = () => {
    setShow(!show);
  };

  return (
    <div
      className={classnames(
        s.dropdown,
        (isActive || show) && s.dropdown_active,
      )}
    >
      <div
        className={s.dropdown__label}
        onClick={onHandleShowMenu}
        id={data.slug || data.id}
      >
        {typeof label === 'function' ? (
          label()
        ) : (
          <span className="f-body">{label}</span>
        )}
        {!disableIcon && (
          <img src={IconArrow} alt="Arrow" width={24} height={24} />
        )}
      </div>
      <div className={s.dropdown__content}>
        <div
          className={classnames(s.dropdown__content_inner, 'unreset', 'f-16')}
        >
          {innerContentHTML ? text : children}
        </div>
      </div>
    </div>
  );
};
