import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { LOAN_GROUPS, LOAN_GROUPS_IDS } from '@/helpers/constants';
import { APP_LINKS } from '@/helpers/links';
import { MyLink } from '@/components/MyLink';
import { TERM_TOOLTIPS } from './tooltips';
import s from './desktop.module.scss';

const COMPARE_KEYS = t => ({
  full_name: t('compare_modal.full_name'),
  investor_earnings: t('compare_modal.investor_earnings'),
  target_annual_return: t('compare_modal.target_annual_return'),
  term: t('compare_modal.term'),
  repayment_type: t('compare_modal.repayment_type'),
  skin_in_the_game: t('compare_modal.skin_in_the_game'),
  protection: t('compare_modal.protection'),
});

export const DesktopTermView = () => {
  const { t: tLoanTerms } = useTranslation('loan_terms');
  const { t: tConstants } = useTranslation('constants');
  const { t } = useTranslation('investing');

  const LOAN_GROUPS_T = LOAN_GROUPS(tConstants);
  const TERM_TOOLTIPS_T = TERM_TOOLTIPS(tLoanTerms);
  const COMPARE_KEYS_T = COMPARE_KEYS(t);

  return (
    <div className={s.wrapper}>
      <div className={s.table}>
        {Object.keys(COMPARE_KEYS_T).map(key => (
          <div className={classnames(s.row, COMPARE_KEYS_T[key])} key={key}>
            <div className={classnames(s.label, 'f-body')}>
              {COMPARE_KEYS_T[key]}
              {TERM_TOOLTIPS_T[key] && TERM_TOOLTIPS_T[key]({})}
            </div>
            <div className="f-body">{LOAN_GROUPS_T.conservative[key]}</div>
            <div className="f-body">{LOAN_GROUPS_T.moderate[key]}</div>
          </div>
        ))}
        <div className={s.row}>
          <div />
          <div className="f-body">
            <MyLink
              className="f-label"
              underline
              href={APP_LINKS.helpLoanTermsGroup(
                LOAN_GROUPS_IDS.conservative.detailLink,
              )}
            >
              {t('compare_modal.learn_more')}
            </MyLink>
          </div>
          <div className="f-body">
            <MyLink
              className="f-label"
              underline
              href={APP_LINKS.helpLoanTermsGroup(
                LOAN_GROUPS_IDS.moderate.detailLink,
              )}
            >
              {t('compare_modal.learn_more')}
            </MyLink>
          </div>
        </div>
      </div>
    </div>
  );
};
