import { useMemo } from 'react';
import classnames from 'classnames';
import Decimal from 'decimal.js-light';
import { useGetPaymentCalculatedAmount } from '@/query/queries/cash/useGetPaymentCalculatedAmount';
import { getCommissionInfo } from './utils';
import s from './index.module.scss';

export const OtherMethod = ({ amount, data, isSelected, onClick }) => {
  const { data: calculation } = useGetPaymentCalculatedAmount({
    amount,
    payment_method: data.type,
  });

  const commission = useMemo(() => {
    if (!calculation) return 0;
    return new Decimal(calculation.amount).sub(amount).toNumber();
  }, [amount, calculation]);

  return (
    <div
      className={classnames(s.item, isSelected && 'selected')}
      onClick={onClick}
    >
      <div className={s.item_info}>
        <div className="f-body">{data.title}</div>
        <div className="f-label">
          {getCommissionInfo(data.type, commission)}
        </div>
      </div>
      <div className={s.item_image}>
        <img src={data.icon} alt={data.title} />
      </div>
    </div>
  );
};
