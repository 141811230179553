export const TIMEZONE_ESTONIA = 'Europe/Tallinn';
export const CURRENCY = '€';
export const EMPTY_DASH = '—';
export const CONTACT_EMAIL = 'ask@scrambleup.com';
export const CONTACT_PHONE = '+372 712 2955';

export const LOAN_GROUPS_IDS = {
  conservative: {
    id: 'conservative',
    detailLink: 'v3-group-a',
  },
  moderate: {
    id: 'moderate',
    detailLink: 'v3-group-b',
  },
  growth: {
    id: 'growth',
    detailLink: 'v3-group-c',
  },
};
export const LOAN_GROUPS = t => ({
  conservative: {
    id: 'conservative',
    investor_earnings: t('investor_earnings_up_to', { value: 1 }),
    target_annual_return: t('target_annual_return_up_to', { value: 12.4 }),
    term: t('term_months', { start: 6, end: 24 }),
    flat_fee_six_month: '5%',
    flat_fee_six_month_extra: t('plus_up_to_percent', { value: 4.4 }),
    seniority: t('seniority_senior'),
    full_name: t('group_value', { value: 'A' }),
    short_name: 'A',
    scope_name: t('group_only', { value: 'A' }),
    downside_protection: t('protection_triple_secured'),
    repayment_type: t('monthly'),
    skin_in_the_game: t('skin_in_the_game_up_to', { value: 20 }),
    disclaimer: t('group_a_disclaimer'),
    protection: t('protection_triple_secured'),
  },
  moderate: {
    id: 'moderate',
    investor_earnings: t('investor_earnings_percent_for_month', {
      percent: 9,
      month: 6,
    }),
    target_annual_return: t('target_annual_return_up_to', { value: 25 }),
    term: t('term_months', { start: 6, end: 24 }),
    flat_fee_six_month: '9%',
    flat_fee_six_month_extra: t('plus_up_to_percent', { value: 49 }),
    seniority: t('seniority_subordinated'),
    full_name: t('group_value', { value: 'B' }),
    short_name: 'B',
    scope_name: t('groups_a_and_b'),
    downside_protection: t('protection_single_secured'),
    repayment_type: t('last_month'),
    skin_in_the_game: '0%',
    disclaimer: t('group_b_disclaimer'),
    protection: t('protection_single_secured'),
  },
  growth: {
    id: 'growth',
    investor_earnings: t('investor_earnings_up_to', { value: 1 }),
    target_annual_return: EMPTY_DASH,
    term: EMPTY_DASH,
    flat_fee_six_month: EMPTY_DASH,
    flat_fee_six_month_extra: EMPTY_DASH,
    seniority: t('seniority_junior'),
    full_name: t('group_value', { value: 'C' }),
    short_name: 'C',
    scope_name: t('groups_a_b_c'),
    downside_protection: EMPTY_DASH,
    repayment_type: EMPTY_DASH,
    skin_in_the_game: EMPTY_DASH,
    disclaimer: EMPTY_DASH,
    protection: t('protection_single_secured'),
  },
});

export const ACCOUNT_TYPES = {
  loan: 'loan',
  cash: 'cash',
};

export const LOAN_GROUPS_SHORT = {
  conservative: 'A',
  moderate: 'B',
  growth: 'C',
};

export const LOAN_GROUPS_NAMES = {
  conservative: 'Group A only',
  moderate: 'Groups A and B',
  growth: 'Groups A, B and C',
};

export const FUNDS_ORIGIN = t => ({
  employment: t('funds_origin.employment'),
  ownership: t('funds_origin.ownership_of_business_company_profit'),
  savings: t('funds_origin.savings'),
  sale_of_property: t('funds_origin.sale_of_property'),
  investment: t('funds_origin.investments'),
  inheritance: t('funds_origin.inheritance'),
  gift: t('funds_origin.gift'),
  other: t('funds_origin.other'),
});

export const PLANNED_MONTHLY_INVESTMENT = {
  1: '< € 500',
  2: "€ 500 - 1'000",
  3: "€ 1'000 - 5'000",
  4: "€ 5'000 - 15'000",
  5: "> € 15'000",
};

export const USER_STATUSES = {
  new: 'new',
  verified: 'verified',
  blocked: 'blocked',
  suspended: 'suspended',
};

export const EARNINGS_STATUSES = t => ({
  no_payments: { label: t('earnings_statuses.no_payments') },
  scheduled: { label: t('earnings_statuses.scheduled') },
  paid: { label: t('earnings_statuses.paid') },
  paid_overdue: { label: t('earnings_statuses.paid_overdue') },
  paid_partially: { label: t('earnings_statuses.paid_partially') },
  paid_partially_late: { label: t('earnings_statuses.paid_partially_late') },
  late: { label: t('earnings_statuses.late') },
  accrued: { label: t('earnings_statuses.accrued') },
});

export const VERIFF_CODES = {
  new: 0,
  started: 7001,
  submitted: 7002,
  verified: 9001,
  notVerified: 9102,
  reSubmitRequest: 9103,
  expired: 9104,
};

export const LINK_TYPES = {
  phone: 'phone',
  web: 'web',
  mail: 'mail',
};

export const PASSWORD_SPECIAL_CHARS = `!"#$%€&'()*+,-./:;<=>?@[\\]^_'{|}~`;

export const MIN_PASSWORD_LENGTH = 8;

export const QUESTION_STATUSES = {
  accepted: 'accepted',
  rejected: 'rejected',
  draft: 'draft',
};

export const BANNER_STATUSES = {
  get_increased_income: 'get_increased_income',
  keep_benefiting_done_type_a: 'keep_benefiting_done_type_a',
  keep_benefiting_done_type_b: 'keep_benefiting_done_type_b',
  keep_benefiting_done_type_c: 'keep_benefiting_done_type_c',
  keep_benefiting_save_type_a: 'keep_benefiting_save_type_a',
  keep_benefiting_save_type_b: 'keep_benefiting_save_type_b',
  keep_benefiting_save_type_c: 'keep_benefiting_save_type_c',
};

export const INVESTMENT_STRATEGIES = {
  standard: 'standard',
  priority: 'priority',
  not_selected: 'not_selected',
};
