import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useModal } from '@/providers/modal';
import { ModalCenterLayout } from '@/kit/Modal/layouts/center';
import { ModalSwipeLayout } from '@/kit/Modal/layouts/swipe';
import { useWindowSize } from '@/hooks/useWindowSize';
import { PAYMENT_RESULT_STATUSES } from '@/containers/AddCashModule/constants';
import { useGetPaymentResultStatus } from '@/query/queries/cash/useGetPaymentResultStatus';
import { PaymentResultSuccess } from './success';
import { PaymentResultFailure } from './fail';
import { MODAL_IDS } from '../../utils/constants';
import s from './index.module.scss';

const id = MODAL_IDS.top_up_result_modal;

export const TopUpResultModal = () => {
  const { openSingleModal, modalIds } = useModal();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('payment_reference');
  const { data: result } = useGetPaymentResultStatus(token);

  useEffect(() => {
    result && !modalIds[id] && token && openSingleModal(id);
  }, [result, token, modalIds]);

  return modalIds[id] && token && result && <Modal result={result} />;
};

const Modal = ({ result }) => {
  const { openSingleModal, closeModal } = useModal();
  const { isMobile } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const onClose = () => {
    navigate(location.pathname, { replace: true });
    closeModal(id);
  };

  const onTryAgain = () => {
    navigate(location.pathname, { replace: true });
    openSingleModal(MODAL_IDS.add_cash_modal);
  };

  const content = (
    <div className={s.modal}>
      {result.payment_state === PAYMENT_RESULT_STATUSES.settled && (
        <PaymentResultSuccess result={result} onClose={onClose} />
      )}
      {result.payment_state === PAYMENT_RESULT_STATUSES.failed && (
        <PaymentResultFailure result={result} onTry={onTryAgain} />
      )}
    </div>
  );

  return isMobile ? (
    <ModalSwipeLayout id={id} onClose={onClose}>
      {content}
    </ModalSwipeLayout>
  ) : (
    <ModalCenterLayout id={id} onClose={onClose} showClose>
      {content}
    </ModalCenterLayout>
  );
};
