import classnames from 'classnames';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { DEFAULT_PADDING, PADDING_OFFSET } from './constants';
import IconClear from './icon_clear.svg';
import s from './index.module.scss';

export const InputField = forwardRef(
  (
    {
      label,
      description,
      leftContainer,
      rightContainer,
      error,
      onClear,
      ...inputProps
    },
    ref,
  ) => {
    const leftRef = useRef(null);
    const rightRef = useRef(null);
    const [inputPadding, setInputPadding] = useState({
      paddingLeft: '0px',
      paddingRight: '0px',
    });

    useEffect(() => {
      const leftWidth = leftRef.current ? leftRef.current.offsetWidth : 0;
      const rightWidth = rightRef.current ? rightRef.current.offsetWidth : 0;

      setInputPadding({
        paddingLeft: leftWidth
          ? `${leftWidth + PADDING_OFFSET}px`
          : `${DEFAULT_PADDING}px`,
        paddingRight: rightWidth
          ? `${rightWidth + PADDING_OFFSET}px`
          : `${DEFAULT_PADDING}px`,
      });
    }, [leftContainer, rightContainer, onClear, inputProps.value]);

    return (
      <div className={s.wrapper}>
        {label && (
          <label htmlFor={inputProps.name} className={s.label}>
            {label}
          </label>
        )}
        <div className={classnames(s.input_wrap, error && s.input_error)}>
          {leftContainer && (
            <div className={s.left} ref={leftRef}>
              {leftContainer}
            </div>
          )}
          <input
            type="text"
            ref={ref}
            className={classnames(s.input, inputProps.className)}
            style={inputPadding}
            {...inputProps}
          />
          {(rightContainer || (onClear && !!inputProps.value)) && (
            <div className={s.right} ref={rightRef}>
              {rightContainer}
              {onClear && !!inputProps.value && (
                <button type="button" className={s.btn_clear} onClick={onClear}>
                  <img src={IconClear} alt="clear" width={24} height={24} />
                </button>
              )}
            </div>
          )}
        </div>
        {error && <div className={classnames('f-label', s.error)}>{error}</div>}
        {description && (
          <div className={classnames('f-label', s.desc)}>{description}</div>
        )}
      </div>
    );
  },
);
