import { Trans } from 'react-i18next';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import { TermTooltip } from './term_tooltip';

export const TERM_TOOLTIPS = t => ({
  investor_earnings: () => (
    <TermTooltip>
      <div className="t_earning">{t('tooltip_earnings')}</div>
    </TermTooltip>
  ),
  target_annual_return: () => (
    <TermTooltip>
      <div className="t_return">{t('tooltip_net_annual_return')}</div>
    </TermTooltip>
  ),
  term: () => (
    <TermTooltip>
      <div className="t_term">
        {t('tooltip_term')}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          {t('tooltip_scramble_loan_terms')}
        </MyLink>
      </div>
    </TermTooltip>
  ),
  repayment_type: () => (
    <TermTooltip>
      <div className="t_repayments" style={{ whiteSpace: 'pre-line' }}>
        <Trans
          ns="loan_terms"
          i18nKey="tooltip_repayments"
          components={{ span: <span style={{ fontWeight: '500' }} /> }}
        />
      </div>
    </TermTooltip>
  ),
  skin_in_the_game: () => (
    <TermTooltip>
      <div className="t_skin">{t('tooltip_skin')}</div>
    </TermTooltip>
  ),
});
