import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getFlagURL, prettyMoney } from '@/helpers/utils';
import s from './index.module.scss';

export const InvestedCountries = ({ data }) => {
  const otherCountries = useMemo(() => data?.countries?.slice(3), [data]);

  if (!data) return null;

  return (
    <div className={s.wrapper}>
      <Country country={data.countries[0]} />
      <Country country={data.countries[1]} />
      <Country country={data.countries[2]} />
      <OtherCountries countries={otherCountries} />
    </div>
  );
};

const Country = ({ country }) => {
  if (!country) return null;

  return (
    <div className={s.place}>
      <div className={classnames(s.place_flag)}>
        <img
          src={getFlagURL(country.country_code)}
          alt={country.country_code}
        />
      </div>
      <div className={classnames(s.place_name, 'f-label')}>
        {country.country_name}
      </div>
      <div className={classnames(s.place_amount, 'f-body')}>
        {prettyMoney(country.allocated_amount)}
      </div>
    </div>
  );
};

const OtherCountries = ({ countries }) => {
  const { t } = useTranslation('investing');
  const totalAmount = React.useMemo(
    () =>
      countries.reduce((acc, country) => {
        acc += country.allocated_amount;
        return acc;
      }, 0),
    [countries],
  );

  if (!totalAmount) return null;

  return (
    <div className={s.other}>
      <div className={s.other_flags}>
        {countries.map((country, index) => {
          if (countries.length === 3 || index <= 1) {
            return (
              <img
                key={country.country_code}
                src={getFlagURL(country.country_code)}
                alt={country.country_code}
              />
            );
          }
        })}
        {countries.length > 3 && (
          <span className={s.other_more}>+{countries.length - 2}</span>
        )}
      </div>
      <div className={s.other_info}>
        <div className={classnames(s.other_name, 'f-label')}>
          {t('countries.other_countries')}
        </div>
        <div className={classnames(s.other_value, 'f-body')}>
          {prettyMoney(totalAmount)}
        </div>
      </div>
    </div>
  );
};
