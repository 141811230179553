import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { cashService } from '../../services/cashService';

export const useGetPaymentCalculatedAmount = params => {
  return useQuery({
    enabled: !!params.payment_method,
    queryKey: [QUERY_KEYS.cash.payment_calculated_amount, params],
    queryFn: () =>
      cashService.calculateTotalAmount(params).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
