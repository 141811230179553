import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { RoundButton } from '@/components/UI/RoundButton';
import { prettyFloatMoney } from '@/helpers/utils';
import { INVESTMENT_STRATEGIES } from '@/helpers/constants';
import { getInvestingResults } from '@/store/actions/investActions';
import { getInvestmentGroups } from '@/store/services/investingServices';
import './index.scss';

const InvestingInfo = ({
  status,
  skipResultFetch,
  className,
  onLoansEdit,
  onStrategyEdit,
}) => {
  const { t } = useTranslation('investing');
  const dispatch = useDispatch();
  const [loanGroups, setLoanGroups] = useState(null);

  const { investingResult } = useSelector(state => state.investStore);
  useEffect(() => {
    const isResult = status.step === 5;
    if (isResult) {
      !skipResultFetch && dispatch(getInvestingResults());
    } else {
      getInvestmentGroups().then(
        res => res && res.success && setLoanGroups(res.data),
      );
    }
  }, []);

  const isDisabled = status.step === 666;
  const isResult = status.step === 5;
  const hasReachedGoal = status.total_remaining_amount <= 0;
  const hasAllocated = !!Number(status.earmarked);

  const groups = [];
  isResult
    ? investingResult.data?.groups
        .filter(group => group.is_active)
        .map(group =>
          groups.push({ title: group.title, amount: group.invested_in_group }),
        )
    : loanGroups &&
      loanGroups
        .filter(item => item.group.is_active)
        .map(item =>
          groups.push({ title: item.group.title, amount: item.amount }),
        );

  return (
    <div
      className={classnames(
        'investing-info',
        isDisabled && 'disabled',
        className,
      )}
    >
      <div className="investing-info__content">
        <div className="investing-info__strategy">
          <div className="investing-info__strategy-header">
            <div className="investing-info__strategy-approach">
              <p className="f-16">{t('inv_info_investment_strategy')}</p>
              <p className="f-26 f-500">
                {status.option === INVESTMENT_STRATEGIES.not_selected && '-'}
                {status.option === INVESTMENT_STRATEGIES.standard &&
                  t('inv_info_standard_strategy')}
                {status.option === INVESTMENT_STRATEGIES.priority &&
                  t('inv_info_priority_strategy')}
              </p>
              {status.option === INVESTMENT_STRATEGIES.priority && (
                <div className="investing-info__strategy-priority f-16 f-400">
                  {t('inv_info_priority_strategy_description')}
                </div>
              )}
            </div>
            {onStrategyEdit &&
              status.step < 4 &&
              status.option !== INVESTMENT_STRATEGIES.not_selected &&
              !hasReachedGoal && (
                <RoundButton
                  label={t('inv_info_edit')}
                  type="button"
                  onClick={onStrategyEdit}
                  className="investing-info__strategy-edit"
                />
              )}
          </div>
        </div>

        <div className="investing-info__summary">
          <div className="investing-info__baskets">
            {isResult ? (
              <div className="investing-info__result-header">
                <div className="investing-info__result-invested">
                  <p className="f-16">{t('inv_info_invested')}</p>
                  <p className="f-42 f-500 tl">
                    {prettyFloatMoney(
                      investingResult.data &&
                        investingResult.data.total_invested,
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <div className="investing-info__baskets-header">
                <div className="investing-info__baskets-earmarked">
                  <p className="f-16">
                    {t('inv_info_total_allocated_for_investing')}
                  </p>
                  <p className="f-42 f-500 tl">
                    {prettyFloatMoney(status.earmarked)}
                  </p>
                </div>
                {onLoansEdit &&
                  status.step < 4 &&
                  hasAllocated &&
                  !hasReachedGoal && (
                    <RoundButton
                      type="button"
                      label={t('inv_info_edit')}
                      onClick={onLoansEdit}
                      className="investing-info__baskets-edit"
                    />
                  )}
              </div>
            )}

            {!!groups.length && (
              <div className="investing-info__baskets-list">
                {groups.map((group, index) => (
                  <div className="investing-info__baskets-item" key={index}>
                    <p className="f-16">{group.title}</p>
                    <p className="f-26 f-500 tl">
                      {prettyFloatMoney(group.amount)}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {onLoansEdit &&
              status.step < 4 &&
              hasAllocated &&
              !hasReachedGoal && (
                <RoundButton
                  type="button"
                  label={t('inv_info_edit')}
                  onClick={onLoansEdit}
                  className="investing-info__baskets-edit bottom"
                  fullWidth
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestingInfo;
