import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Faq, MINIFAQ_SOURCES } from '@/containers/Faq';
import s from './index.module.scss';

const TABS = t => ({
  0: t('faq.general'),
  1: t('faq.strategies'),
  2: t('faq.allocation'),
  3: t('faq.review_and_ready'),
  4: t('faq.round_processing'),
  5: t('faq.result'),
});

export const InvestingFaq = ({ step }) => {
  const [activeTab, setActiveTab] = React.useState(step);
  const { t } = useTranslation('investing');

  const TABS_T = TABS(t);

  useEffect(() => {
    setActiveTab(step);
  }, [step]);

  return (
    <div>
      <Faq source={MINIFAQ_SOURCES.investing} step={activeTab}>
        <div className={s.outer}>
          <div className={s.tabs}>
            {Object.keys(TABS_T).map(key => (
              <div
                key={key}
                className={classnames(
                  s.tab,
                  activeTab.toString() === key && s.tab_active,
                )}
                onClick={() => setActiveTab(Number(key))}
              >
                <span className="f-body">{TABS_T[key]}</span>
              </div>
            ))}
          </div>
        </div>
      </Faq>
    </div>
  );
};
