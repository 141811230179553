import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AppRoutes } from '@/routes';
import store from '@/store/configureStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as serviceWorker from './serviceWorker';
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 1000 * 60 * 5, // Cache stays fresh for 5 minutes
      cacheTime: 1000 * 60 * 10, // Data remains in cache for 10 minutes even if unused
      refetchOnMount: 'stale',
      refetchOnWindowFocus: false,
      refetchOnReconnect: 'always',
      refetchIntervalInBackground: false,
      suspense: false,
    },
    mutations: {
      retry: false,
    },
  },
});

createRoot(document.getElementById('root')).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RouterProvider router={AppRoutes} />
    </Provider>
  </QueryClientProvider>,
);

serviceWorker.unregister();
