import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/query/queryKeys';
import { brandService } from '../../services/brandService';

export const useGetBrandDetails = brandSlug => {
  return useQuery({
    enabled: !!brandSlug,
    queryKey: [QUERY_KEYS.cash.balance, brandSlug],
    queryFn: () =>
      brandService.details(brandSlug).then(response => {
        const { status, data } = response;
        if (status === 200) {
          return data;
        }
        return null;
      }),
  });
};
