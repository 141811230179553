import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import {
  bodyScrollLock,
  calculateCash,
  getFundraisingState,
  prettyFloatMoney,
} from '@/helpers/utils';
import { MyLink } from '@/components/MyLink';
import { APP_LINKS } from '@/helpers/links';
import Logo from '@/components/UI/Icons/Logo';
import { CONTACT_PHONE, EMPTY_DASH } from '@/helpers/constants';
import { LanguageSwitcher } from '@/components/LanguageSwitcher';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { useGetBalance } from '@/query/queries/cash/useGetBalance';
import MenuUser from '../../MenuUser';
import { NAV_ROUTES } from '../routes';
import './index.scss';

export const MobileNavigation = () => {
  const location = useLocation();
  const { t } = useTranslation('navigation');
  const [open, setOpen] = useState(false);
  const { data: balance } = useGetBalance();
  const { referralCode } = useSelector(state => state.referralStore);
  const isActiveReferralBanner = !!referralCode.data?.is_referral_banner_active;

  const cash = calculateCash(balance);
  const { data: fundraisingDates } = useGetFundraisingDates();

  useEffect(() => {
    bodyScrollLock(open);
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  return (
    <>
      <div className="mobile_navigation__wrap">
        <div className="container-inner">
          <div className="mobile_navigation">
            <MyLink
              href={APP_LINKS.dashboard}
              className="mobile_navigation__logo"
            >
              <Logo />
            </MyLink>
            {open && (
              <MyLink
                className="mobile_navigation__phone"
                href={APP_LINKS.phone}
                isExternal
              >
                {CONTACT_PHONE}
              </MyLink>
            )}
            <div className="mobile_navigation__box">
              <LanguageSwitcher />
              <div
                className="mobile_navigation__clickable"
                onClick={() => setOpen(!open)}
              >
                <div
                  className={classnames(
                    'mobile_navigation__burger',
                    open && 'mobile_navigation__burger-active',
                  )}
                >
                  <div className="mobile_navigation__burger-inner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={classnames(
          'mobile_navigation__menu',
          open && 'mobile_navigation__menu-open',
        )}
      >
        <div className="container-inner">
          <div className="mobile_navigation__menu-inner">
            <div className="mobile_navigation__summary">
              <div className="mobile_navigation__summary-top">
                <div>
                  <p className="f-12 f-400">{t('summary_total')}</p>
                  <p className="f20 f-500">
                    {balance ? prettyFloatMoney(balance.total) : EMPTY_DASH}
                  </p>
                </div>

                <div>
                  <p className="f-12 f-400">{t('summary_invested')}</p>
                  <p className="f20 f-500">
                    {balance ? prettyFloatMoney(balance.invested) : EMPTY_DASH}
                  </p>
                </div>

                <div>
                  <p className="f-12 f-400">{t('summary_cash')}</p>
                  <p className="f20 f-500">
                    {balance ? prettyFloatMoney(cash) : EMPTY_DASH}
                  </p>
                </div>
              </div>
              <div className="mobile_navigation__round f-12 f-400">
                {getFundraisingState(fundraisingDates)}
              </div>
            </div>
            <div className="mobile_navigation__links">
              {NAV_ROUTES(t)({ isActiveReferralBanner }).map(route => (
                <NavLink
                  key={route.label}
                  to={route.path}
                  className={({ isActive }) =>
                    `mobile_navigation__link f-16 f-500 ${isActive ? 'mobile_navigation__link-active' : ''}`
                  }
                >
                  {route.label}{' '}
                  {route.icon && <img src={route.icon} alt="icon" />}
                </NavLink>
              ))}
            </div>
            <MenuUser className="mobile_navigation__user" />
          </div>
        </div>
      </div>
    </>
  );
};
