import { useMemo } from 'react';
import { useGetPreAllocateInfo } from '@/query/queries/investing/useGetPreAllocateInfo';

/* Hook that used to retrieve status BEFORE confirmed allocation */
export const useAllocationStatus = () => {
  const { data: preAllocateInfo, isLoading } = useGetPreAllocateInfo();

  const accounts = useMemo(
    () => preAllocateInfo?.accounts || [],
    [preAllocateInfo],
  );

  const accountsByGroup = useMemo(() => {
    return accounts.reduce((res, acc) => {
      res[acc.group] = acc;
      return res;
    }, {});
  }, [accounts]);

  const availableCash = accountsByGroup.cash?.amount || 0;
  const totalAllocated = preAllocateInfo?.total_allocated || 0;
  const bonusAmount = preAllocateInfo?.bonus_amount || 0;
  const availableCashWithoutBonus = Number(availableCash - bonusAmount) || 0;
  const allocatedGroupA = accountsByGroup.conservative?.amount || 0;
  const allocatedGroupB = accountsByGroup.moderate?.amount || 0;
  const noCash = availableCashWithoutBonus < 0.1;
  const hasConfirmed = !!preAllocateInfo?.has_confirmed;
  const usedBonusInGroupA =
    allocatedGroupA < bonusAmount ? allocatedGroupA : bonusAmount;
  const bonusRemaining =
    bonusAmount - usedBonusInGroupA < 0 ? 0 : bonusAmount - usedBonusInGroupA;

  const totalCash = preAllocateInfo
    ? preAllocateInfo.total_allocated + availableCash
    : 0;

  const totalCashWithoutBonus = totalCash - bonusAmount;

  return {
    accounts,
    accountsByGroup,
    noCash,
    availableCash,
    availableCashWithoutBonus,
    totalAllocated,
    bonusAmount,
    allocatedGroupA,
    allocatedGroupB,
    bonusRemaining,
    usedBonusInGroupA,
    totalCash,
    totalCashWithoutBonus,
    hasConfirmed,
    isLoading,
  };
};
