import moment from 'moment/min/moment-with-locales';
import { Trans, useTranslation } from 'react-i18next';
import { useGetFundraisingDates } from '@/query/queries/investing/useGetFundraisingDates';
import { Preloader } from '@/components/Preloader';
import { prettyDate, prettyFloatMoney } from '@/helpers/utils';
import { DATE_FORMAT_MMMM_YYYY } from '@/helpers/common';
import s from './index.module.scss';

const BonusBannerModal = ({ props }) => {
  const { t } = useTranslation('modal');
  const {
    bonus_amount,
    investment_amount,
    promotion_for,
    expiration_days,
    fundraising_end_date,
    start_date,
    end_date,
  } = props;

  const { data: round } = useGetFundraisingDates();

  return props ? (
    <div className={s.modal}>
      <h1 className="f-32 f-500">
        {t('bonus_banner_modal.maximize_your_earning', {
          amountBonus: prettyFloatMoney(bonus_amount),
          amountInvestment: prettyFloatMoney(investment_amount),
        })}
      </h1>
      <section className="f-16-24">
        <h2 className="f-20 f-500">{t('bonus_banner_modal.how_it_work')}</h2>
        <div>
          <Trans
            ns="modal"
            i18nKey="bonus_banner_modal.every_invested_amount"
            values={{
              investmentAmount: prettyFloatMoney(investment_amount),
              startDate: prettyDate(round?.start_date, DATE_FORMAT_MMMM_YYYY),
              bonusAmount: prettyFloatMoney(bonus_amount),
            }}
            components={{ span: <span className="f-500" /> }}
          />
        </div>
        <ul className={s.list}>
          <li>
            <Trans
              ns="modal"
              i18nKey="bonus_banner_modal.investment_less_than"
              values={{ investmentAmount: prettyFloatMoney(investment_amount) }}
              components={{ span: <span className="f-500" /> }}
            />
          </li>
          <li>
            <Trans
              ns="modal"
              i18nKey="bonus_banner_modal.investment_over_amount"
              components={{ span: <span className="f-500" /> }}
              values={{
                investmentAmount: prettyFloatMoney(investment_amount),
                bonusAmount: prettyFloatMoney(bonus_amount),
              }}
            />
          </li>
          <li>
            <Trans
              ns="modal"
              i18nKey="bonus_banner_modal.investment_over_amount_will_get"
              components={{ span: <span className="f-500" /> }}
              values={{
                investmentAmount: prettyFloatMoney(investment_amount * 2),
                bonusAmount: prettyFloatMoney(bonus_amount * 2),
              }}
            />
          </li>
          <li>
            <Trans
              ns="modal"
              i18nKey="bonus_banner_modal.over_x3"
              components={{ span: <span className="f-500" /> }}
              values={{
                investmentAmount: prettyFloatMoney(investment_amount * 3),
                bonusAmount: prettyFloatMoney(bonus_amount * 3),
              }}
            />
          </li>
        </ul>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          {t('bonus_banner_modal.who_is_promotion_for')}
        </h2>
        <div>{promotion_for}</div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          {t('bonus_banner_modal.when_i_will_get_bonus')}
        </h2>
        <div>
          <Trans
            ns="modal"
            i18nKey="bonus_banner_modal.bonuses_will_be_accrued"
            components={{ span: <span className="nowrap" /> }}
            values={{
              date: moment(fundraising_end_date)
                .add(1, 'day')
                .format('MMMM Do, YYYY'),
            }}
          />
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          {t('bonus_banner_modal.can_i_withdraw_my_bonuses')}
        </h2>
        <div>{t('bonus_banner_modal.use_acquired_bonuses')}</div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          {t('bonus_banner_modal.for_how_long_can_i_use_them')}
        </h2>
        <div>
          {t('bonus_banner_modal.use_bonuses_for_up_to', {
            expirationDays: expiration_days,
          })}
        </div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">
          {t('bonus_banner_modal.what_happens_if_i_invest_my_active_bonus')}
        </h2>
        <div>{t('bonus_banner_modal.invested_bonuses_do_not_participate')}</div>
      </section>
      <section className="f-16-24">
        <h2 className="f-20 f-500">How long will this offer last?</h2>
        <div>
          <Trans
            ns="modal"
            i18nKey="bonus_banner_modal.this_promotion_start_on"
            values={{
              startDate: moment(start_date).format('MMMM Do, YYYY'),
              endDate: moment(end_date).format('MMMM Do, YYYY'),
            }}
            components={{ span: <span className="nowrap" /> }}
          />
        </div>
      </section>
    </div>
  ) : (
    <Preloader style={{ height: '50vh', width: '40vw' }} />
  );
};

export default BonusBannerModal;
