import s from './index.module.scss';

export const FullAmount = ({ label, text }) => {
  return (
    <div className={s.full_amount}>
      <div className={s.full_amount_label}>{label}</div>
      <div className={s.full_amount_input}>{text}</div>
    </div>
  );
};
