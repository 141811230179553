import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { lockBodyScroll, unlockBodyScroll } from '@/helpers/scroll';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);
  const location = useLocation();

  useEffect(() => {
    modals.length ? lockBodyScroll() : unlockBodyScroll();
  }, [modals]);

  useEffect(() => {
    closeAllModals();
  }, [location?.pathname]);

  const openModal = modalId => setModals([...modals, modalId]);

  const openSingleModal = modalId => setModals([modalId]);

  const closeModal = modalId => setModals(modals.filter(el => el !== modalId));

  const closeAllModals = () => setModals([]);

  const modalIds = modals.reduce((acc, id) => {
    acc[id] = id;
    return acc;
  }, {});

  return (
    <ModalContext.Provider
      value={{
        modalIds,
        openModal,
        openSingleModal,
        closeModal,
        closeAllModals,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
