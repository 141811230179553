import { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_LINKS } from '@/helpers/links';
import { getUserProfile } from '@/store/actions/profileActions';
import s from './index.module.scss';

const TABS = t => [
  {
    label: t('activity_label'),
    path: APP_LINKS.activity,
  },
  {
    label: t('tax_report_label'),
    path: APP_LINKS.taxReports,
  },
];

const ReportsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('reports');

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  useEffect(() => {
    if (location.pathname === APP_LINKS.reports) {
      navigate(APP_LINKS.activity, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <div className={s.reports_page}>
      <div className="container-inner">
        <h1 className={classnames(s.reports_page__title, 'f-42 f-500')}>
          {t('reports')}
        </h1>
        <div className={s.reports_page__links}>
          {TABS(t).map(tab => (
            <NavLink
              key={tab.path}
              to={tab.path}
              className={({ isActive }) =>
                `${s.reports_page__link} ${isActive ? s.reports_page__link_selected : ''}`
              }
            >
              {tab.label}
            </NavLink>
          ))}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default ReportsPage;
