import { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import s from './index.module.scss';

export const ClampText = ({
  text,
  lineClampNumber = 2,
  className,
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      // Calculate line height
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      // Maximum visible height when clamped
      const maxHeight = lineHeight * lineClampNumber;

      // Check if content requires clamping
      setIsClamped(element.scrollHeight > maxHeight);
    }
  }, [text, lineClampNumber]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  if (!text) return null;

  return (
    <div className={classnames(s.container, className)}>
      <p
        ref={textRef}
        className={classnames(s.text)}
        style={{
          WebkitLineClamp: isExpanded ? 'initial' : lineClampNumber,
        }}
      >
        {text}
      </p>
      {isClamped && (
        <span
          className={classnames(s.button)}
          onClick={onClick || toggleExpand}
        >
          {isExpanded ? ' Read less' : ' Read more'}
        </span>
      )}
    </div>
  );
};
