import classnames from 'classnames';
import s from './index.module.scss';

export const CheckboxButton = ({
  className,
  error,
  children,
  ...checkboxProps
}) => (
  <label
    className={classnames(s.label, error && 'error', className)}
    htmlFor={checkboxProps.name}
  >
    <Checkbox {...checkboxProps} />
    {children}
  </label>
);

export const Checkbox = ({ ...checkboxProps }) => {
  return (
    <span className={s.checkbox}>
      <input
        type="checkbox"
        className={s.checkbox__input}
        id={checkboxProps.value}
        {...checkboxProps}
      />
      <span className={s.checkbox__checkmark}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z"
            fill="#FFD200"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.625 6.4C14.125 5.86667 13.375 5.86667 12.875 6.4L8.75 10.8L7.125 9.06667C6.625 8.53333 5.875 8.53333 5.375 9.06667C4.875 9.6 4.875 10.4 5.375 10.9333L7.875 13.6C8.125 13.8667 8.375 14 8.75 14C9.125 14 9.375 13.8667 9.625 13.6L14.625 8.26667C15.125 7.73333 15.125 6.93333 14.625 6.4Z"
            fill="white"
          />
        </svg>
      </span>
    </span>
  );
};
